<template>
    <div>
<!-- without login -->
<div class="overflow-y-hidden mob-xScroll">
    <div class="row align-items-center navbar-desk" id="navbar-sec">
        <div class="col-md-6 p-0">
            <a class="cursor-pointer" @click="call_withoutHome()">
                <img :src="s3Url + '/raffolux-slogan-yellow_navBar.png'" alt="" class="logo-img" />
            </a>
            <a @click="call_withoutHome()" class="allCompetitionsMargin">
                <h5 class="competion-link">All competitions</h5>
            </a>
            <a @click="charity" class="winnersLinkMargin">
                <h5 class="winner-link">Charity</h5>
            </a>
        </div>

        <div class="col-md-6 text-end">
            <webNewLoginSignUp></webNewLoginSignUp>

        </div>
    </div>

    <div class="row align-items-center navbar-mb" id="navbar-sec">
        <div class="col-4">
            <button type="button" class="btn signUpMobile-btn" @click="modal_selection()">
                SIGN IN
            </button>

        </div>
        <div class="col-4 text-center">
            <a class="cursor-pointer" @click="call_withoutHome()">
                <img :src="s3Url + '/raffolux-slogan-yellow_navBar.png'" alt="" class="logo-img" />
            </a>
        </div>
        <div class="col-4 text-end">
            <div class="position-relative d-inline">
                <img src="https://static.raffolux.com/static/website/redev_images/cartIcon.svg" alt="" @click="cart_withoutlogin" class="new_home_cart_icon">
                <!-- <span v-if="cartCount === 0 || cartCount == undefined"> <span v-if="cart_details && cart_details.length" class="new_cart-badge">{{ cart_details.length }}</span>
                </span>
                <span v-else class="new_cart-badge">{{ cartCount }}</span> -->
                <span v-if="guestCartCount && guestCartCount > 0" class="new_cart-badge">{{ guestCartCount }}</span>
            </div>

            <img :src="`${s3Url}/open-menu.svg`" alt="" class="btn mb-offCanvasImg" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithoutLogin" aria-controls="offcanvasWithoutLogin" />
        </div>
        <!-- <div class="col-4 text-end">
      <img :src="`${s3Url}/open-menu.svg`" />
    </div> -->
    </div>
</div>
<!-- without login ends -->

<!-- withoutlogin sidebar starts-->
<div class="offcanvas offcanvas-start offcanvasWithoutLogin" tabindex="-1" id="offcanvasWithoutLogin" aria-labelledby="offcanvasWithOutLoginLabel">
    <div class="offcanvas-header offcanvasHeaderBg d-block">
        <div class="d-flex justify-content-end">
            <i class="fa-solid fa-xmark canvasClose" data-bs-dismiss="offcanvas" aria-label="Close"></i>
        </div>
        <!-- <div class="menuBarWithouLoginSignUpBg">
            <button type="button" class="btn menuBarWithoutLoginSignUpBonus">SIGN UP BONUS</button>
            <p class="withoutLoginClaimTxt">Claim your VIP sign up bonus when you create an account today</p>
            <div class="withoutLoginOffTxtBg">
                <div class="d-flex align-items-center">
                    <i class="fa-solid fa-gift withoutLoginGiftIcon"></i>
                    <p class="withouLoginMenuOffTxt mb-0">FREE 50% off</p>
                </div>
                <h6>When you sign up and verify your account</h6>
            </div>
            <button type="button" class="btn withoutLoginMenuSignUpBtn mb-signUpBtnHide" @click="webSignUpOpen()" data-bs-toggle="modal" data-bs-target="#staticBackdrop">SIGN UP</button>
            <button type="button" class="btn withoutLoginMenuSignUpBtn web-signUpBtnHide" @click="mobile_signup_modal_selection()">SIGN UP</button>
        </div> -->
    </div>
    <div class="offcanvas-body menuBarWithoutLoginBg">
        <p>menu</p>
        <div class="withoutLoginMenuCard" @click="homePage">
            <span class="d-flex align-items-center position-relative">
                <i class="fa-light fa-house withoutLoginTrophyIcon"></i>
                <h4 class="withoutLoginMenuCardTxt">Home</h4>
            </span>
        </div>
        <div class="withoutLoginMenuCard mb-storeMenuBtnHide" @click="winnersPage">
            <span class="d-flex align-items-center position-relative">
                <i class="fa-regular fa-trophy withoutLoginTrophyIcon"></i>
                <h4 class="withoutLoginMenuCardTxt">Winners</h4>
            </span>
        </div>
        <div class="withoutLoginMenuCard web-storeMenuBtnHide" @click="winnersPage">
            <span class="d-flex align-items-center position-relative">
                <i class="fa-regular fa-trophy withoutLoginTrophyIcon"></i>
                <h4 class="withoutLoginMenuCardTxt">Winners</h4>
            </span>
        </div>
        <!-- <div class="withoutLoginMenuCard">
            <span class="d-flex align-items-center position-relative">
                <i class="fa-light fa-rocket withoutLoginRocketIcon"></i>
                <h4 class="withoutLoginMenuCardTxt">Promotions</h4>
            </span>
        </div> -->
        <div class="withoutLoginMenuCard" @click="charity">
            <span class="d-flex align-items-center position-relative">
                <i class="fa-light fa-ribbon withoutLoginRocketIcon"></i>
                <h4 class="withoutLoginMenuCardTxt">Charity</h4>
            </span>
        </div>
        <div class="withoutLoginMenuCard">
            <span class="d-flex align-items-center position-relative">
                <i class="fa-regular fa-moon withoutLoginMoonIcon"></i>
                <h4 class="withoutLoginMenuCardTxt">Light / Dark</h4>
                <div class="withoutLogintoggleBtn">
                    <div class="toggleMargin">
                        <!-- <input type="checkbox" class="checkbox" id="checkbox">
                        <label for="checkbox" class="checkbox-label">
                            <span class="ball"></span>
                        </label> -->
                        <toggle></toggle>
                    </div>
                </div>
            </span>
        </div>
        <div class="withoutLoginMenuCard" @click="help_support">
            <span class="d-flex align-items-center position-relative">
                <i class="fa-regular fa-circle-question withoutLoginRocketIcon"></i>
                <h4 class="withoutLoginMenuCardTxt">Help & FAQs</h4>
            </span>
        </div>
        <div class="text-center termsSec_links">
            <a @click="terms_conditions" class="menuSecCursor">Terms</a>
            |
            <a @click="privacy_policy" class="menuSecCursor">Privacy </a>
            |
            <a @click="help_support" class="menuSecCursor">Help </a>
            |
            <a @click="responsiblePlay" class="menuSecCursor">Responsible Play </a>
        </div>
        <div class="withoutLoginMediaBg">
            <a href="https://www.facebook.com/raffolux/" class="withoutLoginMediaLinks">
                <img :src="s3Url + '/facebookLightTheme.svg'" class="withoutLoginmediaLight" alt="">
                <img :src="s3Url + '/facebookDarkTheme.svg'" class="withoutLoginmediaDark" alt="">
            </a>
            <a href="https://twitter.com/i/flow/login?redirect_after_login=%2Fraffolux%2F" class="withoutLoginMediaLinks">
                <img :src="s3Url + '/twitterLightTheme.svg'" class="withoutLoginmediaLight" alt="">
                <img :src="s3Url + '/twitterDarkTheme.svg'" class="withoutLoginmediaDark" alt="">
            </a>
            <a href="https://www.instagram.com/raffolux/" class="withoutLoginMediaLinks">
                <img :src="s3Url + '/instagramLightTheme.svg'" class="withoutLoginmediaLight" alt="">
                <img :src="s3Url + '/instagramDarkTheme.svg'" class="withoutLoginmediaDark" alt="">
            </a>
            <a href="https://www.linkedin.com/uas/login?session_redirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fraffolux%2Fabout%2F" class="withoutLoginMediaLinks">
                <img :src="s3Url + '/linkedInLightTheme.svg'" class="withoutLoginmediaLight" alt="">
                <img :src="s3Url + '/linkedInDarkTheme.svg'" class="withoutLoginmediaDark" alt="">
            </a>
        </div>
        <div class="d-flex justify-content-center">
            <!-- <img :src="s3Url + '/gamCareLightTheme.svg'" class="lightGamCareImg" alt=""> -->
            <!-- <img :src="s3Url + '/GamCare_footer_img.png'" class="darkGamCareImg" alt=""> -->
        </div>
        <hr class="withoutLoginMenuHr">
        <div class="d-flex justify-content-center">
            <!-- <a href="https://www.begambleaware.org/">
                <img :src="s3Url + '/gambleAwareLightTheme.svg'" class="withoutmenugambleImg" alt="">
            </a> -->
        </div>

    </div>
</div>
<!-- without login sidebar ends-->
</div>
</template>

<script>
import webNewLoginSignUp from '@/views/webNewLoginSignUp.vue';
import toggle from "../ReusableComponents/toggle.vue";
import "@/assets/css/global.css";
import "@/assets/css/sideBar.css";
import {
    socialImgUrlLink
} from '@/s3bucket.js';
import {
    mapGetters
} from 'vuex';
export default {

    components: {

        webNewLoginSignUp,
        toggle
    },
    data: () => ({
        s3Url: socialImgUrlLink + "redev_images",
        cart_details: [],
    }),

    created() {

        var guest_cart = JSON.parse(sessionStorage.getItem('guest_cartDetails'));
        this.cart_details = guest_cart;

    },
  
    computed: {

        ...mapGetters(['guestCartCount']),
      
        guestCartCount() {
            return this.$store.getters.guestCartCount;
        },
    },

    methods: {

        webSignUpOpen() {
            $(".canvasClose").click();
            dataLayer.push({
                'event': 'sign_up_accessed'
            });
            document.getElementById("web-signIn-tab").style.display = "none";
            document.getElementById("web-signUp-tab").style.display = "block";
            document.getElementById("web-signUp-tab").classList.add("active");
            document.getElementById("web-signIn-tab").classList.remove("active");
            document.getElementById("webSignIn").style.display = "none";
            document.getElementById("webSignUp").style.display = "block";
        },

        modal_selection() {
            $(".canvasClose").click();
            this.$router.push("/loginSignUp/");

            setTimeout(() => {
                document.getElementById("mobSigInTab").classList.add("active");
                document.getElementById("mobileSignUpTab").classList.remove("active");
                document.getElementById("mobSignIn").style.display = "block";
                document.getElementById("mobSignUp").style.display = "none";
            }, 1000);
            this.$emit('call-clear-function');
        },

        mobile_signup_modal_selection() {
            $(".canvasClose").click();
            dataLayer.push({
                'event': 'sign_up_accessed'
            });
            this.$router.push("/loginSignUp/");

            setTimeout(() => {

                document.getElementById("mobileSignUpTab").classList.add("active");
                document.getElementById("mobSigInTab").classList.remove("active");
                document.getElementById("mobSignIn").style.display = "none";
                document.getElementById("mobSignUp").style.display = "block";
            }, 1000);
            this.$emit('call-clear-function'); //emit to loginSignUp.vue to clear the fields
        },

        charity() {
            setTimeout(() => {
                $(".canvasClose").click();
                this.$router.push("/charities/");
                // const charityUrl = "/charities/";
                // window.location.href = charityUrl;
            }, 1000);
        },

        help_support() {
            $(".canvasClose").click();
            this.$router.push("/help/");
        },

        terms_conditions() {
            $(".canvasClose").click();
            this.$router.push("/tcs/");
        },

        privacy_policy() {
            $(".canvasClose").click();
            this.$router.push("/privacy/");
        },

        responsiblePlay() {
            $(".canvasClose").click();
            this.$router.push("/play/");
        },
        winnersPage() {
            setTimeout(() => {
                $(".canvasClose").click();
                this.$router.push("/winners/");
                // const winnersUrl = "/winners/";
                // window.location.href = winnersUrl;
            }, 1000);
        },

        homePage() {
            $(".canvasClose").click();
            // this.$router.push("/");
            // if (this.$route.query.utm_source || this.$route.query.utm_medium || this.$route.query.utm_campaign || this.$route.query.utm_content || this.$route.query.utm_term) {

            // } else {
                const homewithoutLogintUrl = "/";
                window.location.href = homewithoutLogintUrl;
            // }
        },
        call_withoutHome() {
                const homewithoutLogintUrl = "/";
                window.location.href = homewithoutLogintUrl;
                sessionStorage.removeItem('newReferredMobileUser');
        },
        cart_withoutlogin() {
            this.$router.push("/cartWithoutLogin");
        }

    }
};
</script>
