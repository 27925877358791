export const fetch_prizeclaim_count = {
    data: () => ({
      prizeClaimCount: "",
    }),
    async created() {
      // let userSession = null;
      // const cookies = document.cookie.split("; ");
      // for (let i = 0; i < cookies.length; i++) {
      //   const cookie = cookies[i].split("=");
      //   if (cookie[0] === "user_session") {
      //     userSession = cookie[1];
      //     break;
      //   }
      // }
      this.userSession = localStorage.getItem("user_session");
      if (this.userSession) {
        await this.fetch_prizeclaim_count(this.userSession);
      } else {
        localStorage.removeItem("user_session");
            const cookieNameToClear = "user_session";
            document.cookie = `${cookieNameToClear}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        this.$router.push("/");
        
      }
    },
    methods: {
      async fetch_prizeclaim_count(userSession) {
        try {
          const response = await fetch(
            process.env.VUE_APP_API_URL + "/v1/fetchMyPrizeClaimsCountWithLogin",
            {
              method: "POST",
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                Authorization: JSON.parse(userSession),
              },
            }
          );
          const data = await response.json();
          
          this.prizeClaimCount = data.winningTicketsCount;
         // console.log("ssss",this.prizeClaimCount)
        } catch (error) {
          
        }
      },
    },
  };
  