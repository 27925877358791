<template>
<!-- mobile homePrize -->
<div class="row winners-sec m-0">
    <div class="mb-prize-value col p-0">
        <span v-if="prizes_Mln.PrizesWon == ''">
            <h1 class="mb-0">won_amount</h1>
            <h4 class="mb-0">was not passed</h4>
        </span>
        <span v-else>
            <img :src="`${s3Url}redev_images/home-signedout-dark-mob-carousel-shape-17%402x.png`" alt="" class="mb-gift-img" />
            <h1 class="mb-0">
                £
                <span v-if="prizes_Mln.PrizesWon">
                    {{convertToMillion(prizes_Mln.PrizesWon)}}
                </span>
                M
            </h1>
            <h4 class="mb-0">won in prizes</h4>
        </span>
    </div>
    <div class="col p-0 text-center">
        <span v-if="featuredstatistics.RaffleWinners == []">
            <img :src="`${s3Url}redev_images/2023-v4-home-signedin-dark-shape-42%402x.png`" alt="" class="mb-trophy-img">
            <h3>winner_amount</h3>
            <h5>was not passed</h5>
        </span>
        <span v-else>
            <img :src="`${s3Url}redev_images/2023-v4-home-signedin-dark-shape-42%402x.png`" alt="" class="mb-trophy-img">
            <h3><span v-if="raffleWinners.RaffleWinners">{{ convert_tolocalstring_data(raffleWinners.RaffleWinners) }}</span></h3>
            <h5>winners</h5>
        </span>
    </div>
    <div class="col p-0 text-center">
        <span v-if="featuredstatistics.RaisedCharity == []">
            <img :src="`${s3Url}redev_images/home-signedout-dark-mob-shape-18%402x.png`" alt="" class="mb-ribbon-img">
            <h3>raised_amount</h3>
            <h5>was not passed</h5>
        </span>
        <span v-else>
            <img :src="`${s3Url}redev_images/home-signedout-dark-mob-shape-18%402x.png`" alt="" class="mb-ribbon-img" />
            <h3>£{{ Math.round(raisedCharity.RaisedCharity/1000) }}k</h3>
            <h5>donated</h5>
        </span>
    </div>
    <!-- <div class="col p-0 text-center">
        <span>
            <img :src="`${s3Url}redev_images/person_icon.png`" alt="" class="mb-gift-img" />
            <h3>150+</h3>
            <h5>winners a day</h5>
        </span>
    </div> -->

</div>
<!-- mobile new cards -->
<!-- <div class="mob-newInstantCards">
    <ul class="d-flex newMobInstantCards">
        <li>
            <div>
            <img src="https://raffolux-static.s3.eu-west-2.amazonaws.com/static/website/redev_images/newMobInstCard.svg" class="newMobInstCardBg" alt="">
            </div>
        </li>
        <li>
            <div>
            <img src="https://raffolux-static.s3.eu-west-2.amazonaws.com/static/website/redev_images/newMobFlashCard.svg" class="newMobInstCardBg" alt="">
            </div>
        </li>
        <li>
            <div>
            <img src="https://raffolux-static.s3.eu-west-2.amazonaws.com/static/website/redev_images/newMobTechCard.svg" class="newMobTechCardBg" alt="">
            </div>
        </li>
    </ul>
</div> -->
</template>

<script>
import {
    mapGetters,
    mapActions

} from 'vuex';
import {
    socialImgUrlLink
} from '@/s3bucket.js';
import {
    apiURL
} from "@/api/allApis"

export default {
    data: () => ({
        featuredstatistics: [],
        s3Url: socialImgUrlLink,
        // userSession: "",
        prizes_Mln: "",
        raffleWinners: '',
        raisedCharity:'',

    }),

    created() {
        // this.featured_statistics();
        this.fetchPrizesWon();
        this.fetchRaffleWinners();
        this.fetchRaisedCharity();
    },
    computed: {
        ...mapGetters(['getPrizeWon']),
        ...mapGetters(['getRaffleWinner']),
        ...mapGetters(['getRaisedCharity']),


    },
    methods: {

        ...mapActions(['fetch_prizes_won']),
        async fetchPrizesWon() {
            await this.fetch_prizes_won();
            this.prizes_Mln = this.getPrizeWon;            
        },

        ...mapActions(['fetch_raffle_winner']),
        async fetchRaffleWinners() {
            await this.fetch_raffle_winner();
            this.raffleWinners = this.getRaffleWinner;            
        },
         ...mapActions(['fetch_raised_charity']),
        async fetchRaisedCharity() {
            await this.fetch_raised_charity();
            this.raisedCharity = this.getRaisedCharity;           
        },

        // async featured_statistics() {
        //     fetch(
        //             apiURL.featuredstatistics, {
        //                 method: "POST",
        //                 headers: {
        //                     "Content-type": "application/json; charset=UTF-8",
        //                 },
        //             }
        //         )
        //         .then((response) => response.json())
        //         .then((data) => {
        //             this.featuredstatistics = data;
        //         });
        // },
        mobile_signup_modal_selection() {
            dataLayer.push({
                'event': 'sign_up_accessed'
            });
            this.$router.push("/loginSignUp/");

            setTimeout(() => {
                document.getElementById("mobileSignUpTab").classList.add("active");
                document.getElementById("mobSigInTab").classList.remove("active");
                document.getElementById("mobSignIn").style.display = "none";
                document.getElementById("mobSignUp").style.display = "block";
            }, 1000);
            this.$emit('call-clear-function'); //emit to mobileNewLoginSignUp.vue to clear the fields
        },
        convertToMillion(num) {
            const units = ["Million", "B", "T", "Q"];
            const unit = Math.floor((num / 1.0e1).toFixed(0).toString().length);
            const r = unit % 3;
            const x = Math.abs(Number(num)) / Number("1.0e+" + (unit - r)).toFixed(2);
            return x.toFixed(1);
        },
        // convert_k(num) {
        //     return Math.abs(num) > 999 ?
        //         Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "" :
        //         Math.sign(num) * Math.abs(num);
        // },
        convert_tolocalstring_data(dataIn) {
            return Number(dataIn).toLocaleString();
        },
    },
};
</script>
