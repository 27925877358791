<template>
<div class="bannerWithOut_bg">
    <div class="row align-items-center offer-sec-hide page-content-wrapper mb-0" id="offersWithOut-sec">
        <div class="col-md-3 p-0">
            <span class="d-flex align-items-center gap-1">
                <h3 class="banner_payment_txt mb-0">
                    Payments accepted
                </h3>
                <div class="paymentImgDiv">
                    <a><img :src="`${s3Url}redev_images/Visa.svg`" alt="" class="payment_img_links" /></a>
                    <a><img :src="`${s3Url}redev_images/Mastercard.svg`" alt="" class="payment_img_links" /></a>
                    <a><img :src="`${s3Url}redev_images/PayPal.svg`" alt="" class="payment_img_links" /></a>
                    <a><img :src="`${s3Url}redev_images/Google+Pay.svg`" alt="" class="payment_img_links" /></a>
                    <a><img :src="`${s3Url}redev_images/ApplePay.svg`" alt="" class="payment_img_links" /></a>

                </div>
            </span>
        </div>

        <div class="col-md-6">
            <!-- <span class="d-flex align-items-center justify-content-center">
                <h5 class="mb-0 signUpTxt">Never played before?</h5>
                <button type="button" class="btn offers-btn" @click="webSignUpOpen()" data-bs-toggle="modal" data-bs-target="#staticBackdrop">SIGN UP TODAY</button>
            </span> -->
        </div>
        <div class="col-md-3 text-end">
            <a href="https://www.trustpilot.com/review/raffolux.com">

                <!-- <img :src="`${s3Url}redev_images/banner_trustpilot_img.png`" alt="">  -->
                <!-- <img :src="`${s3Url}redev_images/banner_trustpilot_img.png`" alt="">  -->
                <img :src="`${s3Url}redev_images/homeBannerTrust.svg`" alt=""> 
            </a>
            <div class="choose-offer-sec">
                <span class="d-flex align-items-center justify-content-center">
                    <h4 class="mb-0">CHOOSE YOUR SIGN UP OFFER</h4>
                    <button type="button" class="btn offers-btn"><i class="fa-solid fa-gift"></i>OFFERS</button>
                </span>
            </div>
        </div>
    </div>

</div>
<!-- <div class="choose-offer-sec">
    <span class="d-flex align-items-center justify-content-center">
        <h4 class="mb-0">CHOOSE YOUR SIGN UP OFFER</h4>
        <button type="button" class="btn offers-btn" @click="mobile_signup_modal_selection()"><i class="fa-solid fa-gift"></i>OFFERSss</button>
    </span>
</div> -->
</template>

<script>
import {
    socialImgUrlLink
} from '@/s3bucket';
export default {
    data: () => ({
        s3Url: socialImgUrlLink,
    }),

    methods: {

        mobile_signup_modal_selection() {
            dataLayer.push({
                'event': 'sign_up_accessed'
            });
            this.$router.push("/loginSignUp/");

            setTimeout(() => {
                document.getElementById("mobileSignUpTab").classList.add("active");
                document.getElementById("mobSigInTab").classList.remove("active");
                document.getElementById("mobSignIn").style.display = "none";
                document.getElementById("mobSignUp").style.display = "block";
            }, 1000);
            this.$emit('call-clear-function'); //emit to mobileNewLoginSignUp.vue to clear the fields
        },

        webSignUpOpen() {
            $(".canvasClose").click();
            dataLayer.push({
                'event': 'sign_up_accessed'
            });
            document.getElementById("web-signIn-tab").style.display = "none";
            document.getElementById("web-signUp-tab").style.display = "block";
            document.getElementById("web-signUp-tab").classList.add("active");
            document.getElementById("web-signIn-tab").classList.remove("active");
            document.getElementById("webSignIn").style.display = "none";
            document.getElementById("webSignUp").style.display = "block";
        },
    }

}
</script>
