<template>
<!-- <loader v-if="isLoading" class="loaderbg"></loader> -->
<div>
    <!--Guest creation for password Modal -->
    <!-- <div class="modal fade modalBackdropCustom" id="createAcntModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered guestCreateAcntModalBg">
            <div class="modal-content guestCreateAcntModalContentBg">
                <div class="modal-body p-0">
                    <div class="d-flex justify-content-end">
                        <i class="fa-regular fa-xmark GuestCheckCloseMark" data-bs-dismiss="modal" @click="clear_guestDetails"></i>
                    </div>
                    <div class="guestCreateAcntDtls">
                        <h3>Create an account </h3>

                        <div class="row guestIcons">
                            <div class="col-4 text-center">
                                <img src="https://raffolux-static.s3.eu-west-2.amazonaws.com/static/website/redev_images/newcoinImgLight.svg" alt="" class="guestGridIcon">
                                <h5>Earn points on every purchase</h5>
                            </div>
                            <div class="col-4 text-center">
                                <i class="fa-solid fa-grid guestGridIcon"></i>
                                <h5>Choose your ticket numbers</h5>

                            </div>
                            <div class="col-4 text-center">
                                <i class="fa-solid fa-user-plus guestuserIcon"></i>
                                <h5>Earn rewards via referrals</h5>
                            </div>
                        </div>

                        <div class="mb-newCreateIcons">
                            <div class="row align-items-center mb-newEarnPointsTxt">
                                <div class="col-3 text-end">
                                    <img src="https://raffolux-static.s3.eu-west-2.amazonaws.com/static/website/redev_images/newcoinImgLight.svg" alt="" class="guestGridIcon">
                                </div>
                                <div class="col-9 text-start">
                                    <p class="mb-newCreateIconTxt mb-0">Earn points on every purchase</p>
                                </div>
                            </div>
                            <div class="row align-items-center mb-newEarnPointsTxt">
                                <div class="col-3 text-end">
                                    <i class="fa-solid fa-grid guestGridIcon"></i>
                                </div>
                                <div class="col-9 text-start">
                                    <p class="mb-newCreateIconTxt mb-0">Choose your ticket numbers</p>
                                </div>
                            </div>
                            <div class="row align-items-center mb-newEarnPointsTxt">
                                <div class="col-3 text-end">
                                    <i class="fa-solid fa-user-plus guestuserIcon"></i>
                                </div>
                                <div class="col-9 text-start">
                                    <p class="mb-newCreateIconTxt mb-0">Earn rewards via referrals</p>
                                </div>
                            </div>

                        </div>

                        <div class="mb-0">
                            <label for="password" class="form-label guestCreatepaswdlabelName">Enter a password</label>
                            <input type="password" class="form-control guestCreatepaswdInputName" placeholder="enter a secure password" id="password" aria-describedby="emailHelp" v-model.trim="passwordNew" :class="{ 'is-invalid': passwordError }" @input="password_check_guest">
                            <div id="passwordError1" v-if="passwordError" class="invalid-feedback text-left mb-2">
                                {{ passwordError }}
                            </div>
                        </div>
                        <div class="mb-0">
                            <label for="signUpPhno" class="form-label guestCreatepaswdlabelName">Phone Number</label>
                            <div class="SignUpPhSec newPhoneSec">
                                <div class="dropdown">

                                    <button class="btn guestPhBtn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <img v-if="selectedCountry" :src="selectedCountry.image" alt="Country Flag" class="country-flag" />
                                        <span v-if="selectedCountry">
                                            {{ selectedCountry.dial_code }}
                                        </span>
                                        <span v-else>
                                            <img src="https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GB.svg" alt="Default Flag" class="country-flag" />
                                            +44
                                        </span>
                                    </button>
                                    <ul class="dropdown-menu">
                                        <li>
                                            <input type="text" v-model="search" class="form-control" placeholder="Search country..." />
                                        </li>

                                        <li v-for="country in filteredCountryList" :key="country">
                                            <a class="dropdown-item" @click="
                                selectCountry(country);
                                clearSearch();
                              ">
                                                <img :src="country.image" alt="Country Flag" class="country-flag" />
                                                {{ country.name }} {{ country.dial_code }}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <input class="form-control guestCreatephInput" id="signUpPhno" aria-describedby="emailHelp" v-model="contactNum" :class="{ 'is-invalid': contactError }" @click="clear_data_number" @input="contact_num" />
                                <div id="contactNumber" v-if="contactError" class="invalid-feedback text-left">
                                    {{ contactError }}
                                </div>

                            </div>

                        </div>
                        <div class="form-check guestCheckBoxStyles">
                            <input class="form-check-input guestCheckboxStyles" v-model="checkOtp" type="checkbox" value="" id="flexCheckDefault">
                            <label class="form-check-label guestCheckboxLabel" for="flexCheckDefault">
                                I would like to receive exciting updates on raffles, my chosen charity, partner promotions, exclusive discounts and free tickets!
                            </label>
                        </div>
                        <div class="form-check guestCheckBoxStyles">
                            <input class="form-check-input guestCheckboxStyles" v-model="checkSMS" type="checkbox" value="" id="flexCheckDefault">
                            <label class="form-check-label guestCheckboxLabel" for="flexCheckDefault">
                                I would like to receive exclusive offers and discounts via SMS
                            </label>
                        </div>

                    </div>
                    <button type="button" class="btn guestCreateAcntBtn" :disabled="isLoading" @click="validate_account">Create account</button>
                    <p class="guestCreatetermsTxt">By creating an account you agree that you are at least 18 years of age, a UK resident and have read, accept and agree to the <span @click="call_termsAndCondition()"> Terms and Conditions </span> and <span @click="call_privacy()"> Privacy Policy.</span></p>
                    <p id="TotalErrorSignup" class="text-center mt-1 errorMsgStyles" v-if="error" :style="{ color: 'red' }">
                        {{ error }}
                    </p>
                </div>
            </div>
        </div>
    </div> -->

    <!-- account verification Modal -->
    <!-- <div class="modalBackdropCustom modal fade guestModalBg" id="guestpswdVerification" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog signUpModalStyles">
            <div class="modal-content acntVerificationModalBg">
                <div class="modal-body p-0">
                    <div class="d-flex justify-content-end">
                        <i class="fa-solid fa-xmark signUpCloseMark mb-0" data-bs-dismiss="modal" @click="clear_HomePage()"></i>
                    </div>
                    <h2 class="signUpMoreDtlsTxt signUpVerifyTxt">
                        Verify your account to claim your
                        <span class="signUpBonusTxt"> FREE BONUS </span>
                    </h2>
                    <div class="acntVerifyBgPAdding">
                        <div class="mb-0">
                            <label for="signUpPhno3" class="form-label me-2 signUpNameLabelNames signUpNameNumberLabelNames">Phone Number</label>
                            <div class="SignUpAcntPhSec">
                                <div class="dropdown">

                                    <span v-if="countryImage" class="btn signUpPhBtn dropdown-toggle d-flex justify-content-between align-items-center">
                                        <img :src="countryImage" alt="Default Flag" class="country-flag" />
                                        <p class="mb-0">{{ selectedCountryCode }}</p>
                                    </span>
                                </div>
                                <input type="text" class="form-control signUpPhInputFileds signUpNameInputFileds" id="signUpPhno" v-model="contactNum" aria-describedby="emailHelp" disabled />
                            </div>
                        </div>
                        <hr class="signUpHr" />
                        <p class="signUpCode">
                            Please enter the 6-digit code that was just sent to
                            <span class="signUpNum">
                                {{ selectedCountryCode }} {{ contactNum }}
                            </span>
                        </p>

                        <div class="signUpInput-styles">

                            <input type="text" inputmode="numeric" @focus="activeFieldIndex = index" :class="{ active: activeFieldIndex === index }" @keypress="restrictToNumbers($event)" v-for="(field, index) in fields" :key="index" v-model="field.value" maxlength="1" class="signUpInput-field-bg" :id="'inputField' + index" :ref="'inputField' + index" @input="moveToNextField(index)" oninput="this.value=this.value.replace(/[^0-9]/g,'');" />
                            <p v-if="error" class="error-msg text-left mt-1 mb-1 me-2">
                                <i class="fa-solid fa-circle-xmark crossMark-bg"></i>{{ error }}
                            </p>
                        </div>
                        <p class="signUpResendCode">
                            Didn’t receive a code?
                            <span class="signUpNum" @click="send_otp('0')"> Resend code </span>
                        </p>

                        <button type="button" class="btn versignUpverifyBtn " @click="validate_otp">Verify</button>

                        <p class="signUpErrorMsg">
                            If you didnt receive a code please make sure your phone number
                            is correct and try again!
                        </p>
                    </div>
                </div>
            </div>
            <button type="button" class="btn signUpSkipTxt" data-bs-toggle="modal" data-bs-target="#guestskipStepModal">
                Skip this step
            </button>

        </div>
    </div> -->

    <!-- skip this step Modal -->
    <!-- <div class="modalBackdropCustom modal fade" id="guestskipStepModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered signUpModalStyles">
            <div class="modal-content skipStepModalBg">
                <div class="modal-body p-0">
                    <div class="d-flex justify-content-end">
                        <i class="fa-solid fa-xmark signUpCloseMark" data-bs-dismiss="modal" @click="clear_HomePage()"></i>
                    </div>
                    <h3 class="skipModalHeadingTxt">
                        Are you sure you don’t want your Free Bonus?
                    </h3>
                    <div class="skipModalBgPadding">
                        <p>
                            You can always verify and claim your free bonus later by going
                            to the Account Page and following the steps.
                        </p>
                        <button type="button" class="btn signUpVerifyNow" @click="back_to_verification()">
                            Verify now
                        </button>
                        <button type="button" class="btn signUpVerifyNow signUpVerifyLater" @click="route_homepage()">
                            Verify later
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <!-- Modal -->
    <!-- <div class="modalBackdropCustom modal fade" id="guestsignUpSuccessModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered signUpModalStyles">
            <div class="modal-content signUpSuccessBg">
                <div class="modal-body p-0">
                    <div class="d-flex justify-content-end">
                        <i class="fa-solid fa-xmark signUpCloseMark" data-bs-dismiss="modal" @click="clear_HomePage()"></i>
                    </div>
                    <img src="https://static.raffolux.com/static/website/redev_images/shieldimg.png" class="signUpShieldImg" alt="" />
                    <p class="signUpSuccessTxt">Success!</p>
                    <p class="signUpVerifiedTxt">
                        Your account has been verified successfully!
                    </p>

                    <button type="button" class="btn signUpChooseMyBonus" @click="route_homepage()">
                        Get Started
                    </button>
                </div>
            </div>
        </div>
    </div> -->

    <!--Guest sucess account created Modal -->
    <!-- <div class="modal fade modalBackdropCustom" id="guestaccountCreatedModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered guestAcntCreatedModal">
            <div class="modal-content guestAcntCreatedBg">
                <div class="modal-body p-0">
                    <div class="d-flex justify-content-end">
                        <i class="fa-regular fa-xmark GuestCheckCloseMark" data-bs-dismiss="modal"></i>
                    </div>

                    <div class="guestAcntCreatedDescription">
                        <div class="d-flex justify-content-center">
                            <div class="guestTickBg">
                                <i class="fa-sharp fa-solid fa-circle-check guestCicleCheck" @click="clear_guestDetails"></i>
                            </div>
                        </div>
                        <h1>Account created</h1>
                        <p>Welcome to Raffolux, {{ guestfirstName }}!</p>
                        <div class="d-flex justify-content-center">
                            <button type="button" class="btn toRafflesBtn">To the raffles</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div> -->
    <!-- ends here -->

    <div>
        <navBarView></navBarView>
        <homeBanner></homeBanner>
        <SuperFeaturedRaffle></SuperFeaturedRaffle>
        <div class="homeTheme">
            <!-- <toggle></toggle> -->
        </div>

        <div class="content-bg">
            <homePrizeWonMobile></homePrizeWonMobile>
            <homeCard></homeCard>
            <section class="feedback mb-5 mb-md-auto trustCarouselmb-hide">
                <div class=" py-4 px-4 pb-0">

                    <div class="row categoriesTrustPilot-mb-hide">
                        <div class="col-sm-12 carouselPadHome">
                            <div class='container-xl ratingSecPadHome' onclick="window.location='https://uk.trustpilot.com/review/raffolux.com';" style="margin-top: 0px; background-color: #28293D; border-radius: 10px; margin-bottom: 0px;">
                                <div class="trustpilot-widget" data-locale="en-GB" data-template-id="53aa8912dec7e10d38f59f36" data-businessunit-id="5c6d82f060b1cd0001ecd3bd" data-style-height="100%" data-style-width="100%" data-theme="dark" data-stars="5" data-review-languages="en">
                                    <a href="https://uk.trustpilot.com/review/raffolux.com" target="_blank" rel="noopener">Trustpilot</a>
                                </div>
                            </div>
                            <!-- End TrustBox widget -->
                            <div class="trust-bottom-adjustment" style="margin-bottom:-150px"></div>
                        </div>
                    </div>
                </div>

            </section>
            <homePaymentOptions></homePaymentOptions>
            <homePrizeWon></homePrizeWon>
            <!-- <homePaymentOptions></homePaymentOptions> -->
            <!-- <flashCash></flashCash> -->
            <homeAllRaffles></homeAllRaffles>
            <!-- <div class="page-content-wrapper">
                <soldOutRaffles></soldOutRaffles>
            </div> -->
            <footerView></footerView>
        </div>

        <!-- Start of LiveChat (www.livechat.com) code -->
        <noscript>
            <a href="https://www.livechat.com/chat-with/11791575/" rel="nofollow">Chat with us</a>, powered by
            <a href="https://www.livechat.com/?welcome" rel="noopener nofollow" target="_blank">LiveChat</a>
        </noscript>
        <!-- End of LiveChat code -->
    </div>

    <!--reset password Modal -->
    <div class="modal fade modalBackdropCustom" id="resetPswdModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered forgotPswdStyles">
            <div class="modal-content resetPswdBg">
                <div class="modal-body p-0">
                    <div class="d-flex justify-content-end">
                        <i class="fa-solid fa-xmark signUpCloseMark mb-0" data-bs-dismiss="modal"></i>
                    </div>
                    <h4>Reset password</h4>
                    <div class="mb-0">
                        <label for="password" class="form-label resetPswdLabelNames">Password</label>
                        <input type="password" class="form-control resetPswdInputFields" placeholder="choose a password" id="firstName" aria-describedby="emailHelp" v-model="newPswd" @input="password_check">
                        <div id="PasswordError1" v-if="passwordError" style="color:red" class="mr-0 forgotErrorMsg">
                            {{ passwordError }}
                        </div>
                    </div>
                    <div class="mb-0">
                        <label for="lastName" class="form-label resetPswdLabelNames">Confirm password</label>
                        <input type="password" class="form-control resetPswdInputFields mb-0" placeholder="confirm your password" id="lastName" aria-describedby="emailHelp" v-model="confirmPswd" @input="password_conf">
                        <div id="passwordConfError" v-if="passwordConfError" style="color:red" class="mr-0 forgotErrorMsg">
                            {{ passwordConfError }}
                        </div>
                    </div>

                    <div id="enteredEmailError" v-if="pssError" style="color:red" class="mr-0 mt-4 text-align:center">
                        {{ pssError }}
                    </div>

                    <button type="button" class="btn resetBtn disable" v-if="!isFormFilled" :disabled="isFormFilled">Reset
                        password</button>

                    <button type="button" class="btn resetBtn" @click="reset_pswd()" v-else>Reset password</button>
                </div>
            </div>
        </div>
    </div>
    <!-- <v-snackbar v-model="otpbar" :timeout="timeout" :color="color">
        {{ otpmessage }}

    </v-snackbar> -->
</div>
</template>

<script>
import loader from "@/components/ReusableComponents/loader.vue";

import {
    apiURL
} from "@/api/allApis";

import "@/assets/css/style.css";
import "../assets/css/signUp.css";
// import "@/assets/css/guestCheckout.css";
//import "@/assets/js/liveChat.js";
import navBarView from "../components/HomePage/navBarView.vue";
import SuperFeaturedRaffle from "../components/HomePage/superfeaturedRaffle.vue";
import menuBar from "../components/HomePage/menuBar.vue";
import homeBanner from "../components/HomePage/homeBanner.vue";
import homeCard from "../components/HomePage/homeCard.vue";
import homePrizeWon from "../components/HomePage/homePrizeWon.vue";
//import flashCash from "../components/HomePage/flashCash.vue";
import homePrizeWonMobile from "../components/HomePage/homePrizeWonMobile.vue";
import homePaymentOptions from "../components/HomePage/homePaymentOptions.vue";
import homeAllRaffles from "@/components/HomePage/homeAllRaffles.vue";
import footerView from "../components/HomePage/footerView.vue";
import $ from "jquery";
import {
    mapActions,
    mapGetters
} from "vuex";
// import countryList from "@/mixins/CountryList.json";
// import axios from 'axios';
import {
    RaffoluxMixin
} from "@/mixins/pathName.js";
// import soldOutRaffles from "../components/HomePage/soldOutRaffles.vue";
// import toggle from "../components/ReusableComponents/toggle.vue";

export default {
    name: "home",
    components: {
        navBarView,
        menuBar,
        homeBanner,
        homeCard,
        homePrizeWon,
        homePrizeWonMobile,
        // flashCash,
        homePaymentOptions,
        homeAllRaffles,
        // soldOutRaffles,
        footerView,
        // toggle,
        loader: loader,
        SuperFeaturedRaffle,
    },

    data() {
        return {
            error: "",
            //activeFieldIndex: null,
            isResetPasswordModalVisible: false,
            newPswd: "",
            confirmPswd: "",
            userSession: "",
            pssError: "",
            passwordError: "",
            passwordConfError: "",
            showLiveChat: true,
            // guestEmail: "",
            // passwordNew: "",
            // passwordError1: "",
            utmSource: null,
            utmMedium: null,
            utmCampaign: null,
            utmContent: null,
            utmTerm: null,
            pageTitle: 'We love winners | Raffolux',
            metaDescription: 'Welcome to Raffloux, where you can enter online raffles for a chance to win cash, cars, holidays and more! Our luxury online raffles are cheap and easy to enter.',
            metaKeywords: 'uk raffles, best raffle sites, best raffle website, raffle draw website, online raffle, raffles, online raffle sites, online raffle website, best online raffle, raffle competition website, virtual raffle, raffle games online, win online, raffle competition uk, raffle draw online, best online raffle site',
            // contactError: null,
            // guestfirstName: "",
            // contactNum: "",
            // countryList,
            // selectedCountry: null,
            // selectedCountryCode: "",
            // search: "",
            isLoading: false,
            //   isButtonEnabled: false,
            // guest_cart: [],
            // checkSMS: false,
            // checkOtp: false,
            // userSession: "",
            //     fieldValues: null,
            //    point_balance: "0",
            //     otpbar: false,
            //     color: "success",
            //     otpmessage: "Otp sent",
            //     timeout: 3000,
            //     fields: [{
            //             value: "",
            //         },
            //         {
            //             value: "",
            //         },
            //         {
            //             value: "",
            //         },
            //         {
            //             value: "",
            //         },
            //         {
            //             value: "",
            //         },
            //         {
            //             value: "",
            //         },
            //     ],

        };
    },
    mixins: [RaffoluxMixin],
    mounted() {
        //  this.guestEmail = localStorage.getItem("guestEmail");
        //  this.guestfirstName = localStorage.getItem("guestFirstName");
        // const paymentGuestCheck = localStorage.getItem("paymentSucessGuest");

        // if (paymentGuestCheck) {
        //     const modalElement = document.querySelector('#createAcntModal');
        //     const tsetymodal = new bootstrap.Modal(modalElement);
        //     tsetymodal.show();
        // }
        this.newReferredUserData = JSON.parse(sessionStorage.getItem('newReferredUser'));
        this.newReferredMobileUserData = JSON.parse(sessionStorage.getItem('newReferredMobileUser'));
        setTimeout(() => {
            if (this.newReferredUserData) {
                this.showSignupModalRef();

            } else if (this.newReferredMobileUserData) {

                this.$router.push("/loginSignUp/");

                setTimeout(() => {
                    document.getElementById("mobileSignUpTab").classList.add("active");
                    document.getElementById("mobSigInTab").classList.remove("active");
                    document.getElementById("mobSignIn").style.display = "none";
                    document.getElementById("mobSignUp").style.display = "block";
                }, 1000)
            }
        }, 2000)

        if (window.location.href.includes("resetToken")) {
            let token = window.location.href.split("?resetToken=")[1].replace(/^%22|(%22)$/g, '').split('&')[0];
            // console.log("resetToken", token);
            document.cookie = `user_session=${JSON.stringify(token)}`;
            localStorage.setItem("user_session", JSON.stringify(token));

            // Check if the viewport matches a mobile media query
            const isMobile = window.matchMedia("(max-width: 767px)").matches;

            if (isMobile) {
                this.$router.push("/resetPassword");
            } else {
                this.showResetPasswordModal();
            }

        }
        // else {
        //     this.$router.push("/");
        // }

        let forgotPassword = localStorage.getItem("isFP");

        if (forgotPassword) {
            this.showSignupModal();
            localStorage.removeItem("isFP");
        }

        let myrafflesModel = localStorage.getItem("myrafflesModel")
        if (myrafflesModel) {
            this.showSignupModal();
            localStorage.removeItem("myrafflesModel");
        }

        let ExistEmailCheck = localStorage.getItem("ExistEmail")
        if (ExistEmailCheck) {
            this.showSignInModalEmail();
        }

    },

    created() {
        this.guest_cart = JSON.parse(localStorage.getItem('get_pointvalue'));
        //  console.log("nnn",this.guest_cart)
        if (this.$route.query.utm_source) {
            this.utmSource = this.$route.query.utm_source;
            localStorage.setItem('utm_source', this.utmSource);
        }

        if (this.$route.query.utm_medium) {
            this.utmMedium = this.$route.query.utm_medium;
            localStorage.setItem('utm_medium', this.utmMedium);
        }

        if (this.$route.query.utm_campaign) {
            this.utmCampaign = this.$route.query.utm_campaign;
            localStorage.setItem('utm_campaign', this.utmCampaign);
        }
        if (this.$route.query.utm_content) {
            this.utmContent = this.$route.query.utm_content;
            localStorage.setItem('utm_content', this.utmContent);
        }
        if (this.$route.query.utm_term) {
            this.utmTerm = this.$route.query.utm_term;
            localStorage.setItem('utm_term', this.utmTerm);
        }
        document.title = this.pageTitle;
        const metaDescriptionTag = document.querySelector('meta[name="description"]');
        if (metaDescriptionTag) {
            metaDescriptionTag.setAttribute('content', this.metaDescription);
        }
        const metaKeywordsTag = document.querySelector('meta[name="keywords"]');
        if (metaKeywordsTag) {
            metaKeywordsTag.setAttribute('content', this.metaKeywords);
        }

        this.userSession = localStorage.getItem("user_session");
        if (this.userSession) {
            this.$store.dispatch("validate_Token", this.userSession);
            this.validateToken();
        } else {
            localStorage.removeItem("user_session");
            const cookieNameToClear = "user_session";
            document.cookie = `${cookieNameToClear}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;

            const queryParamsArray = [];
            if (localStorage.getItem('utm_source')) queryParamsArray.push(`utm_source=${localStorage.getItem('utm_source')}`);
            if (localStorage.getItem('utm_medium')) queryParamsArray.push(`utm_medium=${localStorage.getItem('utm_medium')}`);
            if (localStorage.getItem('utm_campaign')) queryParamsArray.push(`utm_campaign=${localStorage.getItem('utm_campaign')}`);
            if (localStorage.getItem('utm_content')) queryParamsArray.push(`utm_content=${localStorage.getItem('utm_content')}`);
            if (localStorage.getItem('utm_term')) queryParamsArray.push(`utm_term=${localStorage.getItem('utm_term')}`);
            const queryParams = queryParamsArray.join('&');
            const homeWithoutLoginURL = queryParams ? `/?${queryParams}` : "/";

            let path = sessionStorage.getItem('tlp')
            if (path) {
                //   console.log(sessionStorage.getItem('tlp'))
                this.$router.push(path);

            } else {
                this.$router.push(homeWithoutLoginURL);
            }

        }
        const currentPath = this.$route.path;
        if (currentPath === '/') {
            this.initializeLiveChat();
        } else {
            const chatWidgetContainer = document.getElementById('chat-widget-container');
            if (chatWidgetContainer) {
                chatWidgetContainer.style.display = 'none';
            }
        }

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

    },
    // watch: {
    //     totalEntries() {
    //         // Update point_balance whenever totalEntries changes
    //         this.point_balance = Math.floor(this.totalEntries);
    //     },
    // },

    computed: {

        // totalEntries() {
        //     if (!this.guest_cart || !Array.isArray(this.guest_cart)) {
        //         return 0;
        //     }

        //     return this.guest_cart.reduce((total, raffle) => {
        //         const cost = raffle.entryValue * raffle.entry_cost_gbp;
        //         return total + cost;
        //     }, 0).toFixed(2);
        // },

        // filteredCountryList() {
        //     return this.countryList.filter((country) => {
        //         const searchTerm = this.search.toLowerCase();
        //         const countryName = country.name ? country.name.toLowerCase() : "";
        //         const dialCode = country.dial_code ?
        //             country.dial_code.toLowerCase() :
        //             "";
        //         return (
        //             countryName.includes(searchTerm) || dialCode.includes(searchTerm)
        //         );
        //     });
        // },

        // countryImage() {
        //     const matchedCountry = this.countryList.find(
        //         (country) => country.dial_code === this.selectedCountryCode
        //     );
        //     return matchedCountry ? matchedCountry.image : ""; // Return the image URL or an empty string if no match found
        // },
        isFormFilled() {
            return (
                this.newPswd !== "" &&
                this.confirmPswd !== "" &&
                this.passwordError == '' &&
                this.passwordConfError == ''
            );
        },

        // isButtonEnabled() {
        //     this.error = null;
        //     return this.fields.every((field) => field.value !== "");
        // },

        ...mapGetters(["getValidateTokenData"]),
        ...mapGetters(['getcartCount']),

    },

    methods: {
        ...mapActions(['fetchCartItems']),
        async fetchCartCount() {
            await this.fetchCartItems(this.userSession);

            this.cartCount = this.getcartCount

        },

        // calculateTotalEntries(cart) {
        //     if (!cart || !Array.isArray(cart)) {
        //         return '0.00';
        //     }

        //     const total = cart.reduce((total, raffle) => {
        //         const cost = raffle.entryValue * raffle.entry_cost_gbp;
        //         return total + cost;
        //     }, 0);

        //     return Math.floor(total).toString();
        // },

        ...mapActions(['validate_Token']),
        async validateToken() {
            await this.validate_Token(this.userSession);
            // console.log("this.getValidateTokenData :", this.getValidateTokenData)
            if (this.getValidateTokenData) {

                const queryParamsArray = [];
                if (localStorage.getItem('utm_source')) queryParamsArray.push(`utm_source=${localStorage.getItem('utm_source')}`);
                if (localStorage.getItem('utm_medium')) queryParamsArray.push(`utm_medium=${localStorage.getItem('utm_medium')}`);
                if (localStorage.getItem('utm_campaign')) queryParamsArray.push(`utm_campaign=${localStorage.getItem('utm_campaign')}`);
                if (localStorage.getItem('utm_content')) queryParamsArray.push(`utm_content=${localStorage.getItem('utm_content')}`);
                if (localStorage.getItem('utm_term')) queryParamsArray.push(`utm_term=${localStorage.getItem('utm_term')}`);
                const queryParams = queryParamsArray.join('&');
                const homepageURL = queryParams ? `/homepage?${queryParams}` : "/homepage";
                this.$router.push(homepageURL);
            } else {
                localStorage.removeItem("user_session");
                const cookieNameToClear = "user_session";
                document.cookie = `${cookieNameToClear}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;

                // if (this.$route.query.utm_source || this.$route.query.utm_medium || this.$route.query.utm_campaign || this.$route.query.utm_content || this.$route.query.utm_term) {

                // } else {
                //     this.$router.push("/");
                // }
                const queryParamsArray = [];
                if (localStorage.getItem('utm_source')) queryParamsArray.push(`utm_source=${localStorage.getItem('utm_source')}`);
                if (localStorage.getItem('utm_medium')) queryParamsArray.push(`utm_medium=${localStorage.getItem('utm_medium')}`);
                if (localStorage.getItem('utm_campaign')) queryParamsArray.push(`utm_campaign=${localStorage.getItem('utm_campaign')}`);
                if (localStorage.getItem('utm_content')) queryParamsArray.push(`utm_content=${localStorage.getItem('utm_content')}`);
                if (localStorage.getItem('utm_term')) queryParamsArray.push(`utm_term=${localStorage.getItem('utm_term')}`);
                const queryParams = queryParamsArray.join('&');
                const homeWithoutLoginURL = queryParams ? `/?${queryParams}` : "/";
                this.$router.push(homeWithoutLoginURL);

            }
        },

        // clear_HomePage() {
        //     this.$router.push("/homepage");
        // },

        showResetPasswordModal() {
            const modalElement = document.querySelector('#resetPswdModal');
            const tsetymodal = new bootstrap.Modal(modalElement);
            //  console.log(tsetymodal)
            tsetymodal.show();
        },

        showSignupModal() {
            const modalElement = document.querySelector('#staticBackdrop');
            const tsetymodal = new bootstrap.Modal(modalElement);
            //    console.log(tsetymodal)
            tsetymodal.show();
            document.getElementById("web-signIn-tab").style.display = "block";
            document.getElementById("web-signUp-tab").style.display = "none";
            document.getElementById("web-signUp-tab").classList.remove("active");
            document.getElementById("web-signIn-tab").classList.add("active");
            document.getElementById("webSignIn").style.display = "block";
            document.getElementById("webSignUp").style.display = "none";
        },

        showSignupModalRef() {
            const modalElement = document.querySelector('#staticBackdrop');
            const tsetymodal = new bootstrap.Modal(modalElement);
            // console.log(tsetymodal)
            tsetymodal.show();
            document.getElementById("web-signIn-tab").style.display = "none";
            document.getElementById("web-signUp-tab").style.display = "block";
            document.getElementById("web-signUp-tab").classList.add("active");
            document.getElementById("web-signIn-tab").classList.remove("active");
            document.getElementById("webSignIn").style.display = "none";
            document.getElementById("webSignUp").style.display = "block";
        },

        showSignInModalEmail() {
            const modalElement = document.querySelector('#staticBackdrop');
            const tsetymodal = new bootstrap.Modal(modalElement);
            // console.log(tsetymodal)
            tsetymodal.show();
            document.getElementById("web-signIn-tab").style.display = "block";
            document.getElementById("web-signUp-tab").style.display = "none";
            document.getElementById("web-signUp-tab").classList.remove("active");
            document.getElementById("web-signIn-tab").classList.add("active");
            document.getElementById("webSignIn").style.display = "block";
            document.getElementById("webSignUp").style.display = "none";
        },

        initializeLiveChat() {
            window.__lc = window.__lc || {};
            window.__lc.license = 11791575;

            (function (n, t, c) {
                function i(n) {
                    return e._h ? e._h.apply(null, n) : e._q.push(n);
                }
                var e = {
                    _q: [],
                    _h: null,
                    _v: "2.0",
                    on: function () {
                        i(["on", c.call(arguments)]);
                    },
                    once: function () {
                        i(["once", c.call(arguments)]);
                    },
                    off: function () {
                        i(["off", c.call(arguments)]);
                    },
                    get: function () {
                        if (!e._h)
                            throw new Error("[LiveChatWidget] You can't use getters before load.");
                        return i(["get", c.call(arguments)]);
                    },
                    call: function () {
                        i(["call", c.call(arguments)]);
                    },
                    init: function () {
                        var n = t.createElement("script");
                        (n.async = !0),
                        (n.type = "text/javascript"),
                        (n.src = "https://cdn.livechatinc.com/tracking.js"),
                        t.head.appendChild(n);
                    },
                };
                !n.__lc.asyncInit && e.init();
                n.LiveChatWidget = n.LiveChatWidget || e;
            })(window, document, [].slice);

        },

        async reset_pswd() {

            this.userSession = localStorage.getItem("user_session");

            if (this.newPswd !== this.confirmPswd || this.confirmPswd !== this.newPswd) {
                this.passwordConfError = "Passwords do not match";
            } else {

                try {
                    await fetch(apiURL.changePassword, {
                            method: "POST",
                            headers: {
                                "Content-type": "application/json; charset=UTF-8",
                                Authorization: JSON.parse(this.userSession),
                            },
                            body: JSON.stringify({
                                password: this.newPswd,
                                confirmPassword: this.confirmPswd,
                            }),
                        })
                        .then((response) => response.json())
                        .then((data) => {
                            this.changePasswordResponse = data;
                            // console.log("this.changePasswordResponse", this.changePasswordResponse)

                            if (this.changePasswordResponse == 200) {

                                localStorage.removeItem("user_session");
                                const cookieNameToClear = "user_session";
                                document.cookie = `${cookieNameToClear}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
                                this.$router.push('/')
                                setTimeout(() => {
                                    window.location.reload();
                                    localStorage.setItem("isFP", true)
                                }, 500);
                                if (this.$route.query.utm_source || this.$route.query.utm_medium || this.$route.query.utm_campaign || this.$route.query.utm_content || this.$route.query.utm_term) {

                                } else {
                                    this.$router.push("/");
                                }
                            } else {
                                this.pssError = this.changePasswordResponse
                            }

                        });
                } catch (error) {
                    // console.log(error);
                }
            }
        },

        // contact_num() {
        //     const numericRegex = /^[0-9]+$/;

        //     if (!this.contactNum) {
        //         this.contactError = "Phone number is mandatory";
        //         return false;
        //     } else if (!numericRegex.test(this.contactNum)) {
        //         this.contactError = "Contact number should contain only numbers";
        //         return false;
        //     } else if (this.contactNum.toString().length < 10) {
        //         this.contactError = "Contact number must be 10 or 11 digits";
        //         return false;
        //     } else if (this.contactNum.toString().length > 11) {
        //         this.contactError = "Contact number must be 10 or 11 digits";
        //         return false;
        //     } else {
        //         this.contactError = "";
        //     }
        //     return true;
        // },

        // selectCountry(country) {
        //     this.selectedCountry = country;
        // },

        // clearSearch() {
        //     this.search = "";
        // },

        // password_check() {
        //     this.pssError = ""

        //     if (this.newPswd.trim() === "") {
        //         this.passwordError = "Password is required";
        //         return false;
        //     } else if (this.newPswd.trim().length < 6) {
        //         this.passwordError = "Password must have at least 6 characters";
        //         return false;
        //     } else {
        //         this.passwordError = "";
        //     }
        //     return true;
        // },

        // password_check_guest() {
        //     if (this.passwordNew.trim() === "") {
        //         this.passwordError = "Password is required";
        //         return false;
        //     } else if (this.passwordNew.trim().length < 6) {
        //         this.passwordError = "Password must have at least 6 characters";
        //         return false;
        //     } else {
        //         this.passwordError = "";
        //     }
        //     return true;
        // },

        // password_conf() {
        //     this.pssError = ""

        //     if (this.confirmPswd.trim() === "") {
        //         this.passwordConfError = "Please confirm your password";
        //         return false;
        //     } else if (this.newPswd !== this.confirmPswd || this.confirmPswd !== this.newPswd) {
        //         this.passwordConfError = "Passwords do not match";
        //         return false;
        //     } else {
        //         this.passwordConfError = "";
        //     }
        //     return true;
        // },

        // clear_guestDetails() {
        //     sessionStorage.removeItem('guest_cartDetails');
        //     sessionStorage.removeItem('transcationSetails');
        //     sessionStorage.removeItem('session_cartID');

        //     localStorage.removeItem('paymentSucessGuest');
        //     sessionStorage.removeItem('adding_toCart');
        //     localStorage.removeItem("guestFirstName");
        //     localStorage.removeItem("guestEmail");
        //     localStorage.removeItem('get_pointvalue')

        // },

        // validate_account() {

        //     if (this.password_check_guest() && this.contact_num()) {
        //         this.create_account()
        //     }
        // },

        // async create_account() {

        //     this.isLoading = true;
        //     this.error = "";

        //     try {

        //         this.selectedCountryCode = this.selectedCountry ?
        //             this.selectedCountry.dial_code :
        //             "+44";
        //         const contactNumber = this.selectedCountryCode + this.contactNum;
        //         let signUpData = {
        //             email: this.guestEmail,
        //             password: this.passwordNew,
        //             contact_number: contactNumber,
        //             raffle_updates_opt: this.checkOtp,
        //             sms_updates_opt: this.checkSMS,
        //             point_balance: this.calculateTotalEntries(this.guest_cart)

        //         };

        //         await fetch(apiURL.guestAccountCreation, {
        //                 method: "POST",
        //                 headers: {
        //                     "Content-type": "application/json; charset=UTF-8",
        //                 },
        //                 body: JSON.stringify(signUpData)
        //             })
        //             .then((response) => response.json())
        //             .then((data) => {
        //                 this.responceData = data;

        //                 this.isLoading = false;
        //                 localStorage.removeItem("guestEmail");
        //                 sessionStorage.removeItem('guest_cartDetails');
        //                 localStorage.removeItem('get_pointvalue');

        //                 sessionStorage.removeItem('adding_toCart');
        //                 if (this.responceData.status == 200) {
        //                     document.cookie = `user_session=${JSON.stringify(
        //                        this.responceData.data[0].jwt
        //                         )}`;
        //                     localStorage.setItem("user_session", JSON.stringify(this.responceData.data[0].jwt));

        //                     if (this.responceData.data[0].smsVerification == "true") {
        //                         this.send_otp("0");
        //                         jQuery('#createAcntModal').modal('hide');
        //                         const modalElement = document.querySelector('#guestpswdVerification');
        //                         const tsetymodal = new bootstrap.Modal(modalElement);
        //                         tsetymodal.show();

        //                     } else {
        //                         jQuery('#createAcntModal').modal('hide');
        //                         this.$router.push('/homepage')

        //                     }
        //                 } else if (this.responceData === "Invalid Request") {
        //                     this.error = "Please enter the valid details";
        //                 } else if (this.responceData.status == 400) {
        //                     this.error = this.responceData.message
        //                 } else {
        //                     this.error = "Something went wrong. Please try again";
        //                 }
        //                 if (this.isRaffoluxPath()) {
        //                     // Adalyser Web Tracking for Registration
        //                     //   window.adalyserTracker("trackEvent", "lce2", {}, true);
        //                     const currentTimestamp = new Date().toISOString();
        //                     const _fbpValue = this.getCookieValue("_fbp");
        //                     const _fbcValue = this.getCookieValue("_fbc");
        //                     this.facebook_tracking_completeReg(currentTimestamp, signUpData, _fbpValue, _fbcValue)
        //                 }
        //             });
        //     } catch (error) {
        //         this.error = "Something went wrong. Please try again";
        //         // this.error = error;
        //         this.isLoading = false;

        //     }
        // },

        // facebook_tracking_completeReg(currentTimestamp, signUpData, _fbpValue, _fbcValue) {

        //     this.check_userSession()
        //     try {
        //         fetch(
        //                 apiURL.facebookPixelCompleteRegistration, {
        //                     method: "POST",
        //                     headers: {
        //                         "Content-type": "application/json; charset=UTF-8",
        //                         "Authorization": JSON.parse(this.userSession),
        //                     },
        //                     body: JSON.stringify({
        //                         event_name: "complete_registration",
        //                         event_time: currentTimestamp,
        //                         user_data: {
        //                             emails: signUpData.email,
        //                             first_name: signUpData.first_name,
        //                             last_name: signUpData.last_name,
        //                             external_id: this.userId,
        //                             fbc: _fbcValue || "",
        //                             fbp: _fbpValue || "",

        //                         },

        //                         event_source_url: "https://raffolux.com/",
        //                         action_source: "website",

        //                     }),
        //                 }
        //             )
        //             .then((response) => response.json())
        //             .then((data) => {})
        //     } catch (error) {
        //         this.error = "";
        //     }
        // },

        // getCookieValue(cookieName) {
        //     const name = cookieName + "=";
        //     const decodedCookie = decodeURIComponent(document.cookie);

        //     const cookieArray = decodedCookie.split(';');
        //     for (let i = 0; i < cookieArray.length; i++) {
        //         let cookie = cookieArray[i].trim();
        //         if (cookie.indexOf(name) === 0) {
        //             return cookie.substring(name.length, cookie.length);
        //         }
        //     }

        //     return "";
        // },

        // async send_otp(val) {

        //     this.check_api = val
        //     //      console.log(this.check_api)
        //     this.userSession = localStorage.getItem("user_session");
        //     this.check_userSession();
        //     try {

        //         let x = {
        //             method: 'POST',
        //             headers: {
        //                 'Content-type': 'application/json; charset=UTF-8',
        //                 Authorization: JSON.parse(this.userSession),
        //             },
        //             data: {},
        //             url: apiURL.generateOtpSmsVerification,
        //         };
        //         const response = await axios(x);
        //         this.receivedSms = response.data.message;

        //         if (this.receivedSms) {
        //             this.otpbar = true;

        //             setTimeout(() => {
        //                 this.fields.forEach((field) => {
        //                     field.value = '';
        //                 });
        //             }, 3000);
        //         }
        //         // console.log("newDataforResend OTP:", this.receivedSms);
        //     } catch (error) {

        //         if (this.check_api == "0") {
        //             this.send_otp("1")
        //         } else {
        //             if (error) {
        //                 this.error = error;
        //             }
        //         }

        //     }
        // },

        // check_userSession() {
        //     this.userSession = localStorage.getItem("user_session");
        //     // const decoded = jwt_decode(this.userSession);
        //     // this.userId = decoded.user_id
        // },

        // display() {
        //     this.fieldValues = this.fields.map((field) => field.value).join("");
        //     this.fieldValues = Number(this.fieldValues);
        //     return this.fieldValues;
        // },

        // async validate_otp() {
        //     this.error = null;
        //     this.display();
        //     this.check_userSession();
        //     try {
        //         await fetch(apiURL.verifySmsOtpWithLogin, {
        //                 method: "POST",
        //                 headers: {
        //                     "Content-type": "application/json; charset=UTF-8",
        //                     Authorization: JSON.parse(this.userSession),
        //                 },
        //                 body: JSON.stringify({
        //                     otp: this.fieldValues,
        //                 }),
        //             })
        //             .then((response) => response.json())
        //             .then((data) => {
        //                 this.otpStatus = data;
        //                 this.isButtonEnabled = false;
        //                 this.fields.map((ele, i) => {
        //                     document
        //                         .getElementById("inputField" + i)
        //                         .classList.remove("active");
        //                 });

        //                 if (this.otpStatus.jwt) {
        //                     document.cookie = `user_session=${JSON.stringify(
        //                         this.otpStatus.jwt
        //                         )}`;
        //                     localStorage.setItem("user_session", JSON.stringify(this.otpStatus.jwt));
        //                     jQuery('#guestpswdVerification').modal('hide');
        //                     const modalElement = document.querySelector('#guestsignUpSuccessModal');
        //                     const tsetymodal = new bootstrap.Modal(modalElement);
        //                     tsetymodal.show();

        //                 } else {
        //                     this.error =
        //                         "Incorrect code,please try again or resend a new code";
        //                     setTimeout(() => {
        //                         this.fields.forEach((field) => {
        //                             field.value = "";
        //                         });
        //                     }, 3000);
        //                     this.isButtonEnabled = false;
        //                     this.fields.map((ele, i) => {
        //                         document
        //                             .getElementById("inputField" + i)
        //                             .classList.remove("active");
        //                     });
        //                 }
        //             });
        //     } catch (error) {
        //         this.error = "An unknown error occured";
        //         this.isButtonEnabled = false;
        //         this.fields.map((ele, i) => {
        //             document.getElementById("inputField" + i).classList.remove("active");
        //         });
        //     }
        // },

        // back_to_verification() {
        //     this.error = null
        //     jQuery('#guestskipStepModal').modal('hide');
        //     const modalElement = document.querySelector('#guestpswdVerification');
        //     const tsetymodal = new bootstrap.Modal(modalElement);
        //     tsetymodal.show();

        // },

        // route_homepage() {

        //     jQuery('#guestsignUpSuccessModal').modal('hide');
        //     jQuery('#guestskipStepModal').modal('hide');
        //     this.$router.push('/homepage')
        // },

        // call_privacy() {
        //     this.$router.push("/privacy/")
        // },

        // call_termsAndCondition() {
        //     this.$router.push("/tcs/")
        // },

        // moveToNextField(index) {
        //     this.error = null
        //     const maxLength = 1;
        //     if (
        //         this.fields[index].value.length >= maxLength &&
        //         index < this.fields.length - 1
        //     ) {
        //         this.$refs["inputField" + (index + 1)][0].focus();
        //     }

        //     if (this.fields[index].value.length >= maxLength) {
        //         document.getElementById("inputField" + index).classList.add("active");
        //     } else {
        //         document.getElementById("inputField" + index).classList.remove("active");
        //     }

        //     // Handle the last input field
        //     if (index === this.fields.length - 1) {
        //         this.activeFieldIndex = null; // Turn it grey
        //     }
        // },

        // restrictToNumbers(event) {
        //     // Allow only numeric input (0-9) in the input fields
        //     const charCode = event.keyCode || event.which;
        //     if (charCode < 48 || charCode > 57) {
        //         event.preventDefault();
        //     }
        // },

    }

};
</script>

<style scoped>
.homeTheme {
    display: none;
}

.css-hv3nvw {
    display: block !important
}

.container-xl {
    max-width: 1240px;
}

.form-control.is-invalid {
    padding-left: 0.75rem
}
</style>
