const apiURL = {
  pageData: process.env.VUE_APP_API_URL + "/v1/getRafflePageData",
  fetchTopPricesData: process.env.VUE_APP_API_URL + "/v1/fetchTopPrizes",
  fetchAllPricesData: process.env.VUE_APP_API_URL + "/v1/fetchAllPrizes",
  pageDataWithLogin:
    process.env.VUE_APP_API_URL + "/v1/getRafflePageDataWithLogin",
  fetchTopPricesDataWithLogin:
    process.env.VUE_APP_API_URL + "/v1/fetchTopPrizesWithLogin",
  fetchAllPricesDataWithLogin:
    process.env.VUE_APP_API_URL + "/v1/fetchAllPrizesWithLogin",
  ticketsSelector: process.env.VUE_APP_API_URL + "/v1/fetchTicketsSelector",
  ticketsSelectorWithLogin:
    process.env.VUE_APP_API_URL + "/v1/fetchTicketsSelectorWithLogin",
  addCartData: process.env.VUE_APP_API_URL + "/v1/addToCartWithLogin",
  myRafflesData: process.env.VUE_APP_API_URL + "/v1/fetchMyRafflesWithLogin",
  myRafflesPending:
    process.env.VUE_APP_API_URL + "/v1/fetchMyPendingRafflesWithLogin",
  myRafflesTicketData:
    process.env.VUE_APP_API_URL + "/v1/fetchMyRafflesTicketsWithLogin",
  categoryData: process.env.VUE_APP_API_URL + "/v1/fetchCategory",
  totalRaisedCharityCountData:
    process.env.VUE_APP_API_URL + "/v1/fetchTotalRaisedCharitiesCount",
  allCharitiesData: process.env.VUE_APP_API_URL + "/v1/fetchAllCharities",
  allRaffles: process.env.VUE_APP_API_URL + "/v1/fetchAllRaffles",
  allRafflesWithLogin:
    process.env.VUE_APP_API_URL + "/v1/fetchAllRafflesWithLogin",
  featuredRaffles: process.env.VUE_APP_API_URL + "/v1/fetchFeaturedRaffles",
  featuredRafflesWithLogin:
    process.env.VUE_APP_API_URL + "/v1/fetchFeaturedRafflesWithLogin",
  featuredstatistics: process.env.VUE_APP_API_URL + "/v1/fetchStatistics",
  myCreditData: process.env.VUE_APP_API_URL + "/v1/fetchMyCreditWithLogin",
  cartCount: process.env.VUE_APP_API_URL + "/v1/fetchCartCountWithLogin",
  pointBalance:
    process.env.VUE_APP_API_URL + "/v1/fetchPointsStoreBalanceWithLogin",
  soldOutRaffles: process.env.VUE_APP_API_URL + "/v1/fetchSoldOutRaffles",
  SoldOutRafflesWithLogin:
    process.env.VUE_APP_API_URL + "/v1/fetchSoldOutRafflesWithLogin",
  generateOtpSmsVerification:
    process.env.VUE_APP_API_URL + "/v1/generateOtpSmsVerificationWithLogin",
  signIn: process.env.VUE_APP_API_URL + "/v1/signIn",
  googleLoginData: process.env.VUE_APP_API_URL + "/v1/socialSignIn/google",
  facebookLoginData: process.env.VUE_APP_API_URL + "/v1/socialSignIn/facebook",
  winnersGallery: process.env.VUE_APP_API_URL + "/v1/fetchWinnersGallery",
  deleteItemFromCart:
    process.env.VUE_APP_API_URL + "/v1/deleteItemFromCartWithLogin",
  deleteTicketFromCart:
    process.env.VUE_APP_API_URL + "/v1/deleteTicketFromCartWithLogin",
  discountedPrice:
    process.env.VUE_APP_API_URL + "/v1/fetchDiscountedPriceWithLogin",
  fetchFromCart: process.env.VUE_APP_API_URL + "/v1/fetchFromCartWithLogin",
  cartPayment: process.env.VUE_APP_API_URL + "/v1/cartPaymentWithLogin",
  
  mblGuestCartPayment : process.env.VUE_APP_API_URL + "/v1/cartPayment",
  categoryWithLogin: process.env.VUE_APP_API_URL + "/v1/fetchCategoryWithLogin",
  updateCharity: process.env.VUE_APP_API_URL + "/v1/updateCharityWithLogin",
  charitySupport: process.env.VUE_APP_API_URL + "/v1/charitySupportWithLogin",
  pointsStore: process.env.VUE_APP_API_URL + "/v1/fetchPointsStoreWithLogin",
  winnersGalleryWithLogin:
    process.env.VUE_APP_API_URL + "/v1/fetchWinnersGalleryWithLogin",
  drawDetails: process.env.VUE_APP_API_URL + "/v1/fetchDrawDetailsWithLogin",
  drawDetailsWithoutLogin: process.env.VUE_APP_API_URL + "/v1/fetchDrawDetails",
  sortDrawticketsPurchase:
    process.env.VUE_APP_API_URL + "/v1/sortDrawticketsPurchaseWithLogin",
  sortDrawticketsPurchaseWithoutLogin:
    process.env.VUE_APP_API_URL + "/v1/sortDrawticketsPurchase",
  winnersDatewiseData:
    process.env.VUE_APP_API_URL + "/v1/fetchWinnersDatewiseDataWithLogin",
  winnersDatewiseDataWithoutLogin:
    process.env.VUE_APP_API_URL + "/v1/fetchWinnersDatewiseData",

  winnersDatewiseDataFilter:
    process.env.VUE_APP_API_URL + "/v1/fetchWinnersDatewiseDataFilterWithLogin",

  winnersDatewiseDataFilterWithoutLogin:
    process.env.VUE_APP_API_URL + "/v1/fetchWinnersDatewiseDataFilter",

  winnersMonth: process.env.VUE_APP_API_URL + "/v1/fetchWinnersMonthWithLogin",
  winnersMonthWithoutLogin:
    process.env.VUE_APP_API_URL + "/v1/fetchWinnersMonth",

  // winnersMonthwiseDetails:
  //   process.env.VUE_APP_API_URL + "/v1/fetchWinnersMonthwiseDataWithLogin",
  winnersMonthwiseDetails:
    process.env.VUE_APP_API_URL + "/v1/fetchlatestWinnersWithLogin",
  winnersMonthwiseDetailsWithoutLogin:
    process.env.VUE_APP_API_URL + "/v1/fetchlatestWinners",
  signUp: process.env.VUE_APP_API_URL + "/v1/signUp",
  signUpgoogle: process.env.VUE_APP_API_URL + "/v1/socialSignUp/google",
  signUpfacebook: process.env.VUE_APP_API_URL + "/v1/socialSignUp/facebook",
  pointsClaimRender:
    process.env.VUE_APP_API_URL + "/v1/fetchPointsClaimRenderWithLogin",
  verifySmsOtpWithLogin:
    process.env.VUE_APP_API_URL + "/v1/verifySmsOtpWithLogin ",
  pointsClaimSummary:
    process.env.VUE_APP_API_URL + "/v1/fetchPointsClaimWithLogin ",
  addPointSummaryAddress:
    process.env.VUE_APP_API_URL + "/v1/addAddressWithLogin",
  displayPointSummaryAddress:
    process.env.VUE_APP_API_URL + "/v1/displayAddressWithLogin",
  updatePointSummaryAddress:
    process.env.VUE_APP_API_URL + "/v1/updateAddressDetailsWithLogin",
  deletePointSummaryAddress:
    process.env.VUE_APP_API_URL + "/v1/deleteAddressDetailsWithLogin",
  pointsConfirmation:
    process.env.VUE_APP_API_URL + "/v1/updatePointsClaimWithLogin ",
  displayPrizeSummaryBankDetails:
    process.env.VUE_APP_API_URL + "/v1/fetchBankDetailsWithLogin ",
  addPrizeSummaryBankDetails:
    process.env.VUE_APP_API_URL + "/v1/addBankDetailsWithLogin ",
  updatePrizeSummaryBankDetails:
    process.env.VUE_APP_API_URL + "/v1/updateBankDetailsWithLogin ",
  deletePrizeSummaryBankData:
    process.env.VUE_APP_API_URL + "/v1/deleteBankDetailsWithLogin ",
  displayPrizeSummaryPaypalDetails:
    process.env.VUE_APP_API_URL + "/v1/fetchPaypalDetailsWithLogin ",
  addPrizeSummaryPaypalDetails:
    process.env.VUE_APP_API_URL + "/v1/addPaypalDetailsWithLogin ",
  updatePrizeSummaryPaypalDetails:
    process.env.VUE_APP_API_URL + "/v1/updatePaypalDetailsWithLogin ",
  deletePrizeSummaryPaypalData:
    process.env.VUE_APP_API_URL + "/v1/deletePaypalDetailsWithLogin ",
  fetchFromCartOnPaymentWithLogin:
    process.env.VUE_APP_API_URL + "/v1/fetchFromCartOnPaymentWithLogin ",
  facebookPixelPurchase:
    process.env.VUE_APP_API_URL + "/v1/facebookPixelPurchase ",
  claimMyPrizesWithLogin:
    process.env.VUE_APP_API_URL + "/v1/claimMyPrizesWithLogin ",
    claimMethodChangeWithLogin:process.env.VUE_APP_API_URL + "/v1/claimMethodChangeWithLogin",
  updateAccountWithLogin:
    process.env.VUE_APP_API_URL + "/v1/updateAccountWithLogin ",
  updateServiceOptsWithLogin:
    process.env.VUE_APP_API_URL + "/v1/updateServiceOptsWithLogin ",
  changePasswordWithLogin:
    process.env.VUE_APP_API_URL + "/v1/changePasswordWithLogin ",
  fetchAccountWithLogin:
    process.env.VUE_APP_API_URL + "/v1/fetchAccountWithLogin ",
  generateOtpSmsVerificationForAccountWithLogin:
    process.env.VUE_APP_API_URL +
    "/v1/generateOtpSmsVerificationForAccountWithLogin ",
  verifySmsOtpForAccountWithLogin:
    process.env.VUE_APP_API_URL + "/v1/verifySmsOtpForAccountWithLogin ",
  fetchMyReferralCode: process.env.VUE_APP_API_URL + "/v1/fetchMyReferralCode ",

  displayStatisticsReferralLandingPage:
    process.env.VUE_APP_API_URL + "/v1/fetchStatistics ",
  getRefereeData: process.env.VUE_APP_API_URL + "/v1/getRefereeCode ",
  checkEmail: process.env.VUE_APP_API_URL + "/v1/checkEmail  ",
  fetchNewsAndBlogs: process.env.VUE_APP_API_URL + "/v1/fetchNewsAndBlogs",
  fetchIndividualNewsAndBlogs: process.env.VUE_APP_API_URL + "/v1/fetchNews",
  validateToken: process.env.VUE_APP_API_URL + "/v1/validateToken",
  olderCreditData:
    process.env.VUE_APP_API_URL + "/v1/fetchOlderCreditWithLogin",
  forgotPassword: process.env.VUE_APP_API_URL + "/v1/forgotPassword",
  changePassword: process.env.VUE_APP_API_URL + "/v1/changePassword",
  updateVoucherWithLogin:
    process.env.VUE_APP_API_URL + "/v1/updateVoucherWithLogin",
  fetchSuperFeaturedRaffle:
    process.env.VUE_APP_API_URL + "/v1/fetchSuperFeaturedRaffle",
  fetchSuperFeaturedRaffleWithLogin:
    process.env.VUE_APP_API_URL + "/v1/fetchSuperFeaturedRaffleWithLogin",
  fetchRaffleWinnersThisWeek:
    process.env.VUE_APP_API_URL + "/v1/fetchRaffleWinnersThisWeek",
  guestCheckout: process.env.VUE_APP_API_URL + "/v1/guestCheckout",
  fetchGuestCartOnPayment:
    process.env.VUE_APP_API_URL + "/v1/fetchGuestCartOnPayment",
  guestAccountCreation:
    process.env.VUE_APP_API_URL + "/v1/guestAccountCreation",
  truelayerPayout: process.env.VUE_APP_API_URL + "/v1/payout",
  facebookPixelCompleteRegistration:
    process.env.VUE_APP_API_URL + "/v1/facebookPixelCompleteRegistration",
  claimMethodChangeWithLogin:
    process.env.VUE_APP_API_URL + "/v1/claimMethodChangeWithLogin",
    facebookFirstTimePurchaseWithLogin:
    process.env.VUE_APP_API_URL + "/v1/facebookFirstTimePurchaseWithLogin",
    facebookFirstTimePurchase:
    process.env.VUE_APP_API_URL + "/v1/facebookFirstTimePurchase",

    getPaymentSuccess:
    process.env.VUE_APP_API_URL + "/v1/payment/dna/success",
    getPaymentFailure:
    process.env.VUE_APP_API_URL + "/v1/payment/dna/failure",
    paymentStatus:
    process.env.VUE_APP_API_URL + "/v1/paymentStatus",
    fetchUserCards:
    process.env.VUE_APP_API_URL + "/v1/fetchUserCards",
    addUserCards:
    process.env.VUE_APP_API_URL + "/v1/addUserCards",
    deleteUserCards:
    process.env.VUE_APP_API_URL + "/v1/deleteUserCards",


    


};

module.exports = { apiURL };
