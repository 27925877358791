<template>
<!-- super featured raffle -->
<loader v-if="isLoading" class="loaderbg"></loader>
<div class="SfBackground">
    <div v-if="superFeaturedRaffleData.length > 0" class="homeSFRaffImg web_sFRaffHide" :style="{ 'background-image': `url(${urlLink + (superFeaturedRaffleData[0].XLImageUrl ? superFeaturedRaffleData[0].XLImageUrl : superFeaturedRaffleData[0].MainImageUrl)})` }">
        <div class="row homeSFRaffBg m-0">
            <div class="col-6 p-0 homeSFColWidth">
                <!-- <img src="https://raffolux-static.s3.eu-west-2.amazonaws.com/static/website/redev_images/monthlyMegaPrizeImg.svg" class="homeSFMegaPrzBg"> -->

                <!-- new timer countdown -->
    
                <span v-if="countdownAbove7Day.length">
                    <div class="newJcptPrzBg">
                        <h2><img src="https://raffolux-static.s3.eu-west-2.amazonaws.com/static/website/redev_images/newHomeCrownImg.svg" class="homeCrownIcon">{{ countdownAbove7Day }}</h2>
                    </div>
                </span>

                <div v-if="countdownAbove1Day.length">
                    <h6 class="newDrawFeatureTxt">{{ countdownAbove1Day }}</h6>
                </div>

                <span v-if="superFeaturedRaffleData[0].is_timer_enabled == true && CountdownBellow1Day.length">
                    <div class="newFeatureRaffEndingTimerBg">
                        <p class="mb-0">ENDING IN</p>
                        <h6 class="mb-0"> {{ CountdownBellow1Day }}</h6>
                    </div>
                </span>
                <!-- new timer countdown -->

                <h1 class="homeSFHeadingTtx">{{ superFeaturedRaffleData[0].Title }}</h1>
                <div v-if="superFeaturedRaffleData[0].is_launch_price == true">
                    <p class="homeSFPrize">£{{ superFeaturedRaffleData[0].discounted_entry_cost_gbp }} </p>
                </div>
                <div v-else>
                    <p class="homeSFPrize">£{{ superFeaturedRaffleData[0].entry_cost_gbp }}</p>
                </div>
                <button type="button" class="btn homeSFPlayBtn" @click="call_button(superFeaturedRaffleData[0])">ENTER
                    NOW</button>
            </div>
            <div class="col-6 homeSFColHide"></div>
        </div>

        <charityCarousel></charityCarousel>

    </div>
</div>
<!-- mobile view -->
<div v-if="superFeaturedRaffleData.length > 0" class="homeSFRaffImg mob_sFRaffHide" :style="{ 'background-image': `url(${urlLink  + (superFeaturedRaffleData[0].super_raffleimg_url ? superFeaturedRaffleData[0].super_raffleimg_url : superFeaturedRaffleData[0].MainImageUrl)})` }" @click="call_button(superFeaturedRaffleData[0])">
    <div class="row homeSFRaffBg m-0">
        <div class="col-6 p-0 homeSFColWidth">

            <div class="d-felx justify-content-center">
                <!-- new timer countdown -->
               
                <span v-if="countdownAbove7Day.length" class="d-flex justify-content-center">
                    <div class="newJcptPrzBg">
                        <h2><img src="https://raffolux-static.s3.eu-west-2.amazonaws.com/static/website/redev_images/newHomeCrownImg.svg" class="homeCrownIcon">{{ countdownAbove7Day }}</h2>
                    </div>
                </span>

                <div v-if="countdownAbove1Day.length" class="d-flex justify-content-center">
                    <h6 class="newDrawFeatureTxt">{{ countdownAbove1Day }}</h6>
                </div>

                <span v-if="superFeaturedRaffleData[0].is_timer_enabled == true && CountdownBellow1Day.length" class="d-flex justify-content-center">
                    <div class="newFeatureRaffEndingTimerBg">
                        <p class="mb-0">ENDING IN</p>
                        <h6 class="mb-0"> {{ CountdownBellow1Day }}</h6>
                    </div>
                </span>
                <!-- new timer countdown -->
            </div>

            <!-- <img src="https://raffolux-static.s3.eu-west-2.amazonaws.com/static/website/redev_images/monthlyMegaPrizeImg.svg" class="homeSFMegaPrzBg"> -->
            <h1 class="homeSFHeadingTtx">{{ superFeaturedRaffleData[0].Title }}</h1>
            <div v-if="superFeaturedRaffleData[0].is_launch_price == true">
                <p class="homeSFPrize">£{{ superFeaturedRaffleData[0].discounted_entry_cost_gbp }} </p>
            </div>
            <div v-else>
                <p class="homeSFPrize">£{{ superFeaturedRaffleData[0].entry_cost_gbp }}</p>
            </div>
            <button type="button" class="btn homeSFPlayBtn" @click="call_button(superFeaturedRaffleData[0])">ENTER NOW</button>
        </div>
        <div class="col-6 homeSFColHide"></div>
    </div>
</div>
<!-- super featured raffle ends-->
</template>

<script>
import $ from 'jquery';
import charityCarousel from '@/components/HomePage/charityCarousel.vue';
// import moment from "moment";
import moment from 'moment-timezone';


import {
    apiURL
} from "@/api/allApis";
import {
    urlLink
} from '@/s3bucket';

import "@/assets/css/style.css";
import loader from "../ReusableComponents/loader.vue";

export default {
    name: 'SuperFeaturedRaffle',
    mounted() {
        this.$nextTick(() => {
            this.fetchSuperFeaturedRaffle();
        });
    },
    components: {
        loader: loader,
        charityCarousel
    },
    data: () => ({
        urlLink: urlLink,
        superFeaturedRaffleData: [],
        isLoading: true,
        allCharitiesData: [],
        countdownAbove1Day: {},
        CountdownBellow1Day: {},
        countdownAbove7Day: {},
    }),

    methods: {

        async fetchSuperFeaturedRaffle() {
            try {
                fetch(apiURL.fetchSuperFeaturedRaffle, {
                        method: "POST",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                        },
                    })
                    .then((response) => response.json())
                    .then((data) => {
                        this.superFeaturedRaffleData = data;
                        // console.log('this.superFeaturedRaffleData', this.superFeaturedRaffleData)
                        if (this.superFeaturedRaffleData.length > 0) {
                            this.startCountdown();
                            this.isLoading = false;
                        }
                        this.isLoading = false;
                    });
            } catch (error) {
                this.isLoading = false;
            }
        },

        call_button(item) {
            this.idArray = item;
            this.$router.push({
                name: 'App',
                params: {
                    category: this.idArray.CategoryName,
                    id: this.idArray.RaffleCode,
                    slug: this.idArray.slug
                }
            });
        },

        startCountdown() {

            const countDownDateUTC = moment.utc(this.superFeaturedRaffleData[0].drawing_in);
            const countDownDateUK = countDownDateUTC.tz('Europe/London');
            const countdownTimer = setInterval(() => {
                const updatedNow = moment();
                const updatedDuration = moment.duration(countDownDateUK.diff(updatedNow));
                const newUpdatedays = updatedDuration.asDays();
                const updatedDay = countDownDateUK.format("dddd");
                const updatedDays = String(parseInt(newUpdatedays)).padStart(2, '0');
                const updatedHours = String(updatedDuration.hours()).padStart(2, '0');
                const updatedMinutes = String(updatedDuration.minutes()).padStart(2, '0');
                const updatedSeconds = String(updatedDuration.seconds()).padStart(2, '0');

                if (updatedDays >= 1 && updatedDays < 7) {
                    this.countdownAbove1Day = `Draw ${updatedDay} ${countDownDateUK.format("ha")}`;
                } else if (updatedDays > 7 && this.superFeaturedRaffleData[0].RetailPrice >= 10000) {
                    this.countdownAbove7Day = `JACKPOT DRAW`;
                } else if (updatedDays < 1) {
                    this.CountdownBellow1Day = `${updatedHours} : ${updatedMinutes} : ${updatedSeconds}`;
                }

                if (updatedDuration <= 0) {
                    clearInterval(countdownTimer);
                }
            }, 1000);

        },
    }
}
</script>

<style scoped>

</style>