import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/HomePage.vue'
import MobilePage from '../views/mobileLogin.vue'
import MobileErrorLogin from "../views/mobileErrorLogin.vue";
const routes = [

  {
    path: "/mobileLogin",
    name: "mobileHome",
    component: MobilePage,
  },
  {
    path: "/mobileErrorLogin",
    name: "mobileErrorLogin",
    component: MobileErrorLogin,
  },
  {
    path: "/guestUserSignUp",
    name: "guestUserSignUp",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/guestUserSignUp.vue"),
  },
  {
    path: "/:category?/raffle/:id/:slug?/",
    name: "App",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/RaffleDetails.vue"),
  },

  {
    path: "/loginSignUp/",
    name: "mobileNewLoginSignUp",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "@/views/mobileNewLoginSignUp.vue"
      ),
  },
  {
    path: "/moreDetails",
    name: "moreDetails",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/WithLoginComponents/mobileSignUp/moreDetails.vue"
      ),
  },
  {
    path: "/verifyAccount",
    name: "verifyAccount",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/WithLoginComponents/mobileSignUp/verifyAccount.vue"
      ),
  },
  {
    path: "/skipVerification",
    name: "skipVerification",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/WithLoginComponents/mobileSignUp/skipVerification.vue"
      ),
  },
  {
    path: "/successVerification",
    name: "successVerification",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/WithLoginComponents/mobileSignUp/successVerification.vue"
      ),
  },
  {
    path: "/freeBonus",
    name: "freeBonus",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/WithLoginComponents/mobileSignUp/freeBonus.vue"
      ),
  },
  {
    path: "/activatedBonus",
    name: "activatedBonus",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/WithLoginComponents/mobileSignUp/activatedBonus.vue"
      ),
  },

  {
    path: "/forgotPassword",
    name: "forgotPassword",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/WithLoginComponents/mobileSignUp/forgotPassword.vue"
      ),
  },

  {
    path: "/forgotPassEmailLink",
    name: "forgotPassEmailLink",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/WithLoginComponents/mobileSignUp/forgotPassEmailLink.vue"
      ),
  },

  {
    path: "/resetPassword",
    name: "resetPassword",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/WithLoginComponents/mobileSignUp/resetPassword.vue"
      ),
  },

  {
    // path: '/:category/raffleWithLogin/:id/:slug/',
    path: "/raffleWithLogin",
    name: "AppWithLogin",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "@/views/RaffleDetailsWithLogin.vue"
      ),
  },
  // {
  //   path: "/raffleEndedWithLogin",
  //   name: "raffleEndedWithLogin",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "about" */ "@/components/RaffleDetailsWithLogin/raffleEndedWithLogin.vue"
  //     ),
  // },
  {
    path: "/claimSummary",
    name: "claimSummary",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "@/components/WithLoginComponents/PointsStore/claimSummary.vue"
      ),
  },
  // {
  //   path: "/prizeClaimSummary",
  //   name: "prizeClaimSummary",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "about" */ "@/components/WithLoginComponents/PrizeClaim/prizeClaimSummary.vue"
  //     ),
  // },

  {
    path: "/charities/",
    name: "charities",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "@/components/CharityPage/charityWithoutLogin.vue"
      ),
  },
  {
    path: "/charityLogin",
    name: "charityLogin",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "@/components/WithLoginComponents/Charity/charityWithLogin.vue"
      ),
  },

  {
    path: "/myRaffles",
    name: "myRaffles",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "@/components/WithLoginComponents/MyRaffles/myRaffles.vue"
      ),
  },

  {
    path: "/userError",
    name: "userError",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "@/components/LoginSignupPage/errorMessage.vue"
      ),
  },
 

  {
    path: "/:pathMatch(.*)*",
    name: "pageNotFound",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "@/components/StaticPages/pageNotFound.vue"
      ),
     
  },
  {
    path: "/trueLayerFailed",
    name: "trueLayerFailed",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/components/WithLoginComponents/prizeclaimSystem/trueLayerFailurePage.vue"),
  },

  {
    path: "/homepage",
    name: "homeLogin",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/homeLogin.vue"),
  },
  {
    path: "/winnersWithLogin",
    name: "winners",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/WithLoginComponents/Winners/winnersPageNew.vue"
      ),
  },
  {
    path: "/drawDetails/:drawCode",
    name: "drawDetails",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/WithLoginComponents/Winners/drawDetails.vue"
      ),
  },
  {
    path: "/winners/",
    name: "winnersWithoutLogin",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "@/components/WinnerswithoutLogin/winnersPageNew.vue"
      ),
  },
  {
    path: "/whowon/:drawCode/",
    name: "drawDetailsWithoutLogin",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/WinnerGallery/drawDetailsWithoutLogin.vue"
      ),
  },

  {
    path: "/cart",
    name: "cart",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/WithLoginComponents/cart/cart.vue"
      ),
  },
  {
    path: "/dnaPayments",
    name: "dnaPayments",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/WithLoginComponents/cart/dnaPayments.vue"
      ),
  },
  {
    path: "/dnaPaymentsWithoutLogin",
    name: "dnaPaymentsWithoutLogin",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/cartWithoutLogin/dnaPaymentsWithoutLogin.vue"
      ),
  },

  {
    path: "/mblDnaPaymentsWithLogin",
    name: "mblDnaPaymentsWithLogin",
    component: () =>
      import(
         "../components/WithLoginComponents/cart/mblDnaPaymentsWithLogin.vue"
      ),
  },

  {
    path: "/mblDnaPaymentsWithoutLogin",
    name: "mblDnaPaymentsWithoutLogin",
    component: () =>
      import(
         "../components/cartWithoutLogin/mblDnaPaymentsWithoutLogin.vue"
      ),
  },

  {
    path: "/mblDnaModalWithLogin",
    name: "mblDnaModalWithLogin",
    component: () =>
      import(
         "../components/WithLoginComponents/cart/mblDnaModalWithLogin.vue"
      ),
  },

  {
    path: "/mblDnaModalWithoutLogin",
    name: "mblDnaModalWithoutLogin",
    component: () =>
      import(
         "../components/cartWithoutLogin/mblDnaModalWithoutLogin.vue"
      ),
  },
  
  {
    path: "/cartNew",
    name: "cartNew",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/WithLoginComponents/cart/newCart.vue"
      ),
  },
  {
    path: "/guestCheckoutNew",
    name: "guestCheckoutNew",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/cartWithoutLogin/guestCheckoutNew.vue"
      ),
  },
  {
    path: "/paymentSuccess",
    name: "paymentSuccess",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/WithLoginComponents/cart/paymentSuccess.vue"
      ),
  },


  // {
  //   path: "/paymentSuccess1",
  //   name: "paymentSuccess1",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "about" */ "../components/WithLoginComponents/cart/paymentSuccess1.vue"
  //     ),
  // },
  // {
  //   path: "/paymentGuest",
  //   name: "paymentGuest",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "about" */ "../components/cartWithoutLogin/paymentGuest.vue"
  //     ),
  // },

  {
    path: "/paymentFailure",
    name: "paymentFailure",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/WithLoginComponents/cart/paymentFailure.vue"
      ),
  },
  {
    path: "/paymentPending",
    name: "paymentPending",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/WithLoginComponents/cart/paymentPending.vue"
      ),
  },
  {
    path: "/cartWithoutLogin",
    name: "cartWithoutLogin",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/cartWithoutLogin/cartWithoutLogin.vue"
      ),
  },
  {
    path: "/paymentSuccesswithoutLogin",
    name: "paymentSuccesswithoutLogin",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/cartWithoutLogin/paymentSuccesswithoutLogin.vue"
      ),
  },
  {
    path: "/paymentFailurewithoutLogin",
    name: "paymentFailurewithoutLogin",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/cartWithoutLogin/paymentFailurewithoutLogin.vue"
      ),
  },
  {
    path: "/paymentPendingwithoutLogin",
    name: "paymentPendingwithoutLogin",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/cartWithoutLogin/paymentPendingwithoutLogin.vue"
      ),
  },
  {
    path: "/myCredit",
    name: "myCredit",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/WithLoginComponents/My_Credit/myCredit.vue"
      ),
  },
  {
    path: "/pointsStore",
    name: "pointsStore",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/WithLoginComponents/PointsStore/pointsStore.vue"
      ),
  },
  {
    path: "/pointsConfirmation",
    name: "pointsConfirmation",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/WithLoginComponents/PointsStore/pointsClaimConfirmation.vue"
      ),
  },

  {
    path: "/category/:categoryData/",
    name: "category",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/categories/categoryData.vue"
      ),
    props: true,
  },
  {
    path: "/categoryLogin",
    name: "categoryLogin",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/WithLoginComponents/Category/categoryLogin.vue"
      ),
  },
  {
    path: "/winners_gallery/",
    name: "winnerGallery",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/WinnerGallery/winnerGallery.vue"
      ),
  },
  {
    path: "/winnerGalleryLogin",
    name: "winnerGalleryLogin",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/WithLoginComponents/WinnerGallery/winnerGalleryLogin.vue"
      ),
  },
  // {
  //   path: "/prizeClaim",
  //   name: "prizeClaim",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "about" */ "../components/WithLoginComponents/PrizeClaim/prizeClaim.vue"
  //     ),
  // },
 
  {
    path: "/prizeConfirmationNew",
    name: "prizeConfirmationNew",
    component: () =>
      import(
        "../components/WithLoginComponents/prizeclaimSystem/prizeclaimSystemConfirmation.vue"
      ),
  },
  {
    path: "/user/banks",
    name: "truelayerData",
    component: () =>
      import(
        "../components/WithLoginComponents/prizeclaimSystem/prizeclaimTruelayerData.vue"
      ),
  },
  {
    path: "/prizeclaimSystem",
    name: "prizeclaimSystem",
    component: () =>
      import(
        "../components/WithLoginComponents/prizeclaimSystem/prizeclaimSystem.vue"
      ),
  },
  
  {
    path: "/prizeCashClaimsWithLoginSummary",
    name: "prizeCashClaimsWithLoginSummary",
    component: () =>
      import(
        "../components/WithLoginComponents/prizeclaimSystem/prizeCashClaimsWithLoginSummary.vue"
      ),
  },


  {
    path: "/prizeclaimSystemSummary",
    name: "prizeclaimSystemSummary",
    component: () =>
      import(
        "../components/WithLoginComponents/prizeclaimSystem/prizeclaimSystemSummary.vue"
      ),
  },

  {
    path: "/privacy/",
    name: "privacy",
    component: () => import("../components/StaticPages/privacyPolicy.vue"),
  },

  {
    path: "/tcs/",
    name: "tcs",
    component: () => import("../components/StaticPages/termsAndConditions.vue"),
  },

  {
    path: "/play/",
    name: "play",
    component: () => import("../components/StaticPages/responsiblePlay.vue"),
  },

  {
    path: "/help/",
    name: "help",
    component: () => import("../components/StaticPages/helpAndSupport.vue"),
  },
  {
    path: "/newslist/",
    name: "newslist",
    component: () => import("../components/StaticPages/newsAndBlogs.vue"),
  },
  {
    path: "/news/:slug/",
    name: "newsAndBlogsExtensionPage",
    component: () =>
      import("../components/StaticPages/newsAndBlogsExtensionPage.vue"),
    props: true,
  },

  {
    path: "/workWithUs",
    name: "workWithUs",
    component: () => import("../components/StaticPages/workWithUs.vue"),
  },
  {
    path: "/referralPage",
    name: "referralPage",
    component: () =>
      import("../components/WithLoginComponents/refferalPage/referralPage.vue"),
  },

  {
    path: "/refferalLandingPage/:rafCode/",
    name: "refferalLandingPage",
    component: () =>
      import(
        "../components/WithLoginComponents/refferalPage/refferalLandingPage.vue"
      ),
  },

  {
    path: "/accountsPage",
    name: "accountsPage",
    component: () => import("../views/accountDetails.vue"),
  },

  {
    path: "/accountPersonalInfo",
    name: "accountPersonalInfo",
    component: () =>
      import(
        "../components/WithLoginComponents/accountPage/accountMobilePages/accountPersonalInfo.vue"
      ),
  },

  {
    path: "/accountPayout",
    name: "accountPayout",
    component: () =>
      import(
        "../components/WithLoginComponents/accountPage/accountMobilePages/accountPayout.vue"
      ),
  },

  {
    path: "/accountMarketing",
    name: "accountMarketing",
    component: () =>
      import(
        "../components/WithLoginComponents/accountPage/accountMobilePages/accountMarketing.vue"
      ),
  },

  {
    path: "/accountChangePassword",
    name: "accountChangePassword",
    component: () =>
      import(
        "../components/WithLoginComponents/accountPage/accountMobilePages/accountChangePassword.vue"
      ),
  },

  {
    path: "/productFeeds",
    name: "productFeeds",
    component: () => import("../components/productFeeds/productFeeds.vue"),
  },
  {
    path: "/trueLayerIncomplete",
    name: "trueLayerIncomplete",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/components/WithLoginComponents/prizeclaimSystem/incompleteTruelayer.vue"),
  },
  {
    path: "/trueLayercomplete",
    name: "trueLayercomplete",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/components/WithLoginComponents/prizeclaimSystem/completeTruelayer.vue"),
  },
  {
    path: "/androidRoute",
    name: "androidRoute",
    component: () =>
      import( "@/components/vueMobileApp/androidRoute"),
  },
  {
    path: "/iosRoute",
    name: "iosRoute",
    component: () =>
      import( "@/components/vueMobileApp/iosRoute"),
  },
  {
    path: "/",
    name: "home",
    component: HomePage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
})
export default router
